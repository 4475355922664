import React from 'react';
import { FlexBox, Text, Button } from '@eltoro-ui/components';

interface Props {
  cost: number;
  isDisabled: boolean;
  onConfirm: VoidFunction;
}

const ResultFooter = ({ cost, onConfirm, isDisabled }: Props) => {
  return (
    <FlexBox justifyContent="space-between" alignItems="center" UNSAFE_className="footer">
      <Text
        on="white"
        weight="bold"
        textAlign="left"
        UNSAFE_style={{ color: '#2B1F0A', fontSize: 14 }}
      >
        Final Ad Spend
      </Text>
      <FlexBox justifyContent="space-between" alignItems="center" gap="32px">
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 24 }}
        >
          ${cost.toFixed(2)}
        </Text>
        <Button
          kind="primary"
          size="xl"
          disabled={isDisabled}
          onClick={onConfirm}
          UNSAFE_style={{ fontWeight: 'bold', fontSize: '1rem' }}
        >
          Confirm
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default ResultFooter;
