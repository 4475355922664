import React from 'react';

import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

import { Button, Modal, Text } from '@eltoro-ui/components';
import type { ModalProps } from '@eltoro-ui/components';

import type { TCommonSubscription, TCommonUser, TCurrentSubscription } from 'types';

import { ComingSoonFeatures, subscriptionsBulletPoints } from 'Utils/constants';

import cross from 'assets/Images/cross.png';
import premium_honey from 'assets/Images/honey-premium.png';
import basic_honey from 'assets/Images/honey-basic.png';
import type { SubscriptionCardProps } from '../../SubscriptionCard';

import './UpdatePlanModal.scss';
import { UserStatus } from '../../../../../../enums';

type UpdatePlanModalProps = {
  user: TCommonUser;
  subscription: TCommonSubscription;
  onCancel: () => void;
  onSubscribe: SubscriptionCardProps['onSubscribe'];
  onUpdateSubscription: SubscriptionCardProps['onUpdateSubscription'];
  loading: boolean;
};

const UpdatePlanModal = ({
  user,
  subscription,
  onCancel,
  onSubscribe,
  onUpdateSubscription,
  loading,
}: UpdatePlanModalProps & ModalProps) => {
  const userIsActive = user?.status === UserStatus.ON_HOLD || user?.status === UserStatus.ACTIVE;
  const subscriptionNameTitle = `Activate ${
    subscription.name.includes('premium') ? 'Premium' : 'Basic'
  }`;
  const suggestingTitle = subscription.name.includes('premium')
    ? 'Enjoy exclusive access to'
    : 'Enjoy access to';

  const subscriptionDescription = subscription.name.includes('premium')
    ? 'Activate the premium plan today and unlock the full potential of our services!'
    : 'Activate the basic plan and use the app with its core features.';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const name_title = `${subscription.name.includes('premium') ? 'Upgrade' : 'Downgrade'} to ${
    subscription.name.includes('premium') ? 'Premium' : 'Basic'
  }`;

  const description = subscription.name.includes('premium')
    ? 'Activate the premium plan today and unlock the full potential of our services!'
    : 'Switching back to basic plan will still let you to use the app with its core features, yet you will lose access to some of them.';

  const updateText = subscription.name.includes('premium')
    ? 'Upgrade to Premium'
    : 'Downgrade to Basic';

  const detailsTitle = subscription.name.includes('premium')
    ? 'Enjoy exclusive access to'
    : 'You will lose access to';

  const bullet_points = subscriptionsBulletPoints
    .find(({ id }) => (userIsActive ? id.includes('premium') : id === subscription.id))
    ?.bullet_points?.map(point => {
      if (subscription.name.includes('basic') && userIsActive) {
        const basic_points = subscriptionsBulletPoints.find(({ id }) => id.includes('basic'));
        if (
          basic_points?.bullet_points.find(basic_point => basic_point.name === point.name) &&
          point.description
        ) {
          const new_description = point.description?.reduce((acc: string[], cur: string) => {
            if (
              !basic_points?.bullet_points
                .find(basic_point => point.description && basic_point.name === point.name)
                ?.description?.includes(cur)
            )
              return [...acc, cur];
            return acc;
          }, []);

          return { ...point, description: new_description };
        }
      }
      return point;
    })
    ?.filter(point => {
      if (subscription.name.includes('basic') && userIsActive) {
        const basic_points = subscriptionsBulletPoints.find(({ id }) => id.includes('basic'));

        if (!basic_points?.bullet_points.find(basic_point => basic_point.name === point.name))
          return point;

        if (
          basic_points?.bullet_points.find(basic_point => basic_point.name === point.name) &&
          point.description
        ) {
          return point.description?.some(desc => {
            return !basic_points?.bullet_points
              .find(basic_point => point.description && basic_point.name === point.name)
              ?.description?.includes(desc);
          });
        }
        return false;
      }
      return true;
    })
    ?.map(({ name, description: bull_desc }) => (
      <React.Fragment key={`${subscription.id}-${name}`}>
        <li>
          <Text
            on="grey-500"
            weight="normal"
            size="m"
            textAlign="center"
            UNSAFE_className={`${
              subscription?.name === 'beewo-subscription-basic' ? 'basic' : 'premium'
            } `}
          >
            <span>
              {name}
              {!!description?.length && ':'}
            </span>
            {ComingSoonFeatures.includes(name) && (
              <Tippy placement="top" content="Coming Soon...">
                <span style={{ marginLeft: '8px' }}>
                  <FontAwesomeIcon icon={faStopwatch} style={{ color: '#FCD917' }} />
                </span>
              </Tippy>
            )}
          </Text>
        </li>
        {!!bull_desc?.length && (
          <ul>
            {bull_desc?.map((des, i: number) => (
              <li key={`${des}${i}`}>
                <span>{des}</span>
                {ComingSoonFeatures.includes(des) && (
                  <Tippy placement="top" content="Coming Soon...">
                    <span style={{ marginLeft: '8px' }}>
                      <FontAwesomeIcon icon={faStopwatch} style={{ color: '#FCD917' }} />
                    </span>
                  </Tippy>
                )}
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    ));

  return (
    <Modal className="update-sub-modal" offClick={!loading ? onCancel : () => undefined}>
      <img
        src={cross}
        onClick={!loading ? onCancel : () => undefined}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <div className="subscription_container">
        <div className="subscription_container-info">
          <img
            className={subscription.name.includes('premium') ? 'premium' : 'basic'}
            src={subscription.name.includes('premium') ? premium_honey : basic_honey}
            alt={subscription.name}
          />
          <span className="subscription_container-info-name">
            {userIsActive ? name_title : subscriptionNameTitle}
          </span>
          <span className="subscription_container-info-cost">
            {subscription.name.includes('premium') && 'for'}{' '}
            {formatter.format(subscription.metadata.price)}/month
          </span>
          <span className="subscription_container-info-description">
            {userIsActive ? description : subscriptionDescription}
          </span>
        </div>
        <div className="subscription_container-divider" />
        <div className="subscription_container-details">
          <span className="subscription_container-details-title">
            {userIsActive ? detailsTitle : suggestingTitle}
          </span>
          <ul className="basic">{bullet_points}</ul>
        </div>
      </div>
      <div className="subscription_actions">
        <Button size="l" onClick={onCancel} disabled={loading} weight="bold">
          Cancel
        </Button>
        <Button
          size="l"
          loading={loading}
          disabled={loading}
          onClick={
            !userIsActive
              ? onSubscribe({ subscription_id: subscription.id })
              : onUpdateSubscription({
                  id: user?.current_subscription?.sub_id as TCurrentSubscription['sub_id'],
                  plan_id: subscription.id,
                })
          }
          kind="primary"
          weight="bold"
        >
          {userIsActive ? updateText : subscriptionNameTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default UpdatePlanModal;
