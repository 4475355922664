import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { Button, FlexBox, Text } from '@eltoro-ui/components';
import CustomIcons from 'assets/icons';

import { getCanvaAuthorization, getUser, revoke } from 'canva-integration/services';

import { useCanvaIntegrationContext } from 'contexts/CanvaIntegrationContext';

import { TRootState } from 'types';

import '../PersonalInfoSection/PersonalInfoSection.scss';

export default function Integrations() {
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const { isAuthorized, setIsAuthorized, setDisplayName } = useCanvaIntegrationContext();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkAuthorizationAndSetName = async () => {
      try {
        if (isAuthorized) {
          const { profile } = await getUser();
          if (profile.display_name) setDisplayName(profile.display_name);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkAuthorizationAndSetName();
  }, [isAuthorized]);

  const onDisconnectClick = async () => {
    try {
      setIsLoading(true);
      await revoke();
      setIsAuthorized(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConnectClick = async () => {
    try {
      setIsLoading(true);
      const result = await getCanvaAuthorization(userData.email);
      setIsAuthorized(result);
    } catch (error) {
      console.error(error);
      setIsAuthorized(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="PersonalInfoSectionContainer">
      <div className="PersonalInfoSectionContainer__header">
        <Text on="white" size="l" UNSAFE_className="PersonalInfoSectionContainer__heading">
          Integrations
        </Text>
      </div>
      <FlexBox padding="24px 0">
        <div className="canva-container">
          <FlexBox gap="8px" alignItems="center">
            <CustomIcons name="canva_icon" fontSize={28} />
            <Text on="white" size="l" weight={600}>
              Canva
            </Text>
          </FlexBox>
          <Button
            loading={isLoading}
            UNSAFE_className={classNames('canva-connect-button', { connected: isAuthorized })}
            kind="text"
            weight={700}
            onClick={isAuthorized ? onDisconnectClick : onConnectClick}
          >
            {isAuthorized ? 'Disconnect' : 'Connect'}
          </Button>
        </div>
      </FlexBox>
    </div>
  );
}
