import React, { ReactElement, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './PageRoutes.scss';

export type PagesType = {
  path: string;
  exactPath?: boolean;
  title: string;
  component: () => ReactElement;
};

type PageRoutesPropsType = {
  pages: PagesType[];
};

export const PageRoutes = ({ pages }: PageRoutesPropsType) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);

  return (
    <div className="PageRoutes">
      <Switch>
        {pages.map((page, index) => (
          <Route key={index} exact={page.exactPath} path={page.path} component={page.component} />
        ))}
      </Switch>
    </div>
  );
};
