import React, { Dispatch, SetStateAction } from 'react';
import { AudienceType } from 'types';
import FlightOptions from './FlightOptions';
import Audiences from './Audiences';

interface Props {
  startDate: Date;
  endDate: Date;
  minDays: number;
  currentDays: number;
  days: number;
  onStartDateChange: (newDate: Date) => void;
  onEndDateChange: (newDate: Date) => void;
  handleUpdateProspects: (updatedProspects: number, maxProspectsCount: number) => void;
  currentProspectsCount: number;
  prospectsCount: number;
  minProspCount: number;
  audiencesList?: AudienceType[];
  setIsValid: Dispatch<
    SetStateAction<{
      cost: boolean;
      flightDates: boolean;
      audiences: boolean;
    }>
  >;
  handleUpdateAudienceExclusion: (checked: boolean, id: number) => void;
  handleUpdateChangedAudiences: (id: number, prospCount: number, aud?: AudienceType) => void;
  excludedAudiences: number[];
  changedAudiences: AudienceType[];
}

const Options = ({
  startDate,
  endDate,
  currentDays,
  days,
  minDays,
  handleUpdateProspects,
  onStartDateChange,
  onEndDateChange,
  currentProspectsCount,
  prospectsCount,
  audiencesList,
  minProspCount,
  setIsValid,
  handleUpdateAudienceExclusion,
  handleUpdateChangedAudiences,
  excludedAudiences,
  changedAudiences,
}: Props) => {
  return (
    <>
      <FlightOptions
        setIsValid={setIsValid}
        startDate={startDate}
        endDate={endDate}
        currentDays={currentDays}
        days={days}
        minDays={minDays}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
      <Audiences
        setIsValid={setIsValid}
        minProspCount={minProspCount}
        currentProspectsCount={currentProspectsCount}
        prospectsCount={prospectsCount}
        listData={audiencesList}
        excludedAudiences={excludedAudiences}
        changedAudiences={changedAudiences}
        handleUpdateProspects={handleUpdateProspects}
        handleUpdateAudienceExclusion={handleUpdateAudienceExclusion}
        handleUpdateChangedAudiences={handleUpdateChangedAudiences}
      />
    </>
  );
};

export default Options;
