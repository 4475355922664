import {
  ResponseType,
  TChargebeeCreateUser,
  TSubscription,
  TCreateSubscription,
  TAddBundle,
  TTransactions,
  TGetCard,
  AddCard,
  TAddCardRequestBody,
  TRemoveCard,
  EditCard,
  TEditCardRequestBody,
  TGetCardDetails,
  TCurrentSubscription,
} from 'types';
import requests from '../http';

export const createChargebeeUser = async (
  body: Partial<any>
): Promise<ResponseType<TChargebeeCreateUser>> => requests.post('/chargebee/create_user', body);

export const getChargebeePackages = async (): Promise<ResponseType<TSubscription>> =>
  requests.get('/chargebee/subscriptions');

export const chargebeeSubscription = async (
  body: Partial<any>
): Promise<ResponseType<TCreateSubscription>> =>
  requests.post('/chargebee/create_subscription', body);

export const updateSubscription = async (
  body: Partial<any>
): Promise<ResponseType<TCreateSubscription>> =>
  requests.put('/chargebee/update_subscription', body);

export const cancelSubscription = async ({
  sub_id,
}: {
  sub_id: TCurrentSubscription['sub_id'];
}): Promise<ResponseType<TCreateSubscription>> =>
  requests.post(`/chargebee/cancel_subscription/${sub_id}`);

export const cancelUpcomingSubscription = async ({
  sub_id,
}: {
  sub_id: TCurrentSubscription['sub_id'];
}): Promise<ResponseType<TCreateSubscription>> =>
  requests.post(`/chargebee/cancel_upcoming_sub/${sub_id}`);

export const reactivateSubscription = async (
  body: Partial<any>
): Promise<ResponseType<TCreateSubscription>> =>
  requests.post('/chargebee/reactivate_subscription', body);

export const chargebeeBundles = async (): Promise<ResponseType<any>> =>
  requests.get('/chargebee/bundles');

export const addChargebeeBundle = async (body: Object): Promise<ResponseType<TAddBundle>> =>
  requests.post('/chargebee/add_bundle', body);

export const getChargebeeTransactions = async (
  page: number,
  size: number
): Promise<ResponseType<TTransactions>> =>
  requests.get(`/chargebee/transactions?page=${page}&size=${size}`);

export const getPaymentCards = async (): Promise<ResponseType<TGetCard>> =>
  requests.get('/chargebee/get_card', undefined);

export const addCard = async (body: TAddCardRequestBody): Promise<ResponseType<AddCard>> =>
  requests.post('/chargebee/add_card', body);

export const editCard = async (body: TEditCardRequestBody): Promise<ResponseType<EditCard>> =>
  requests.put('/chargebee/edit_card', body);

export const removeCard = async (body: Object): Promise<ResponseType<TRemoveCard>> =>
  requests.post('/chargebee/remove_card', body);

export const makePrimary = async (card_id: TGetCardDetails['id']): Promise<ResponseType<any>> =>
  requests.post('/chargebee/make_primary', { card_id });
