import { Button, Modal, Table, Text } from '@eltoro-ui/components';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading, Section, Spinner } from 'Components';
import { getLocalDateTime } from 'Helpers';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getCampaigns } from 'Requests';
import { useDispatch } from 'react-redux';
import { currentCampaignName } from 'Redux/actions';
import { getCompaignPerformance } from 'Requests/Request_Methods/campaignMethods';
import { usePermissionContext } from 'contexts/PermissionContext';
import nodata from 'assets/Images/nodata.png';
import { multiOverlappingOptions } from '../../barOptions';
import './CampaignPerformance.scss';

export const CampaignPerformance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [loader, setloader] = useState(true);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [unselect, setunselect] = useState();
  const [campaignName, setcampaignName] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState<any[]>([]);
  const [pages, setPages] = useState<any>();
  const [LoadingList, setLoadingList] = useState(false);
  const [barData, setbarData] = useState({});
  const [submitList, setsubmitList] = useState(false);
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoadingList(true);
    if (currentPage === 1) {
      setloader(true);
    }
    getCampaignsList();
  }, [currentPage]);
  useEffect(() => {
    dispatch(currentCampaignName(campaignName));
  }, [campaignName]);
  const getCampaignsList = () => {
    getCampaigns(currentPage, 20, null, null, null, false).then((res: any) => {
      setCampaigns([...campaigns, ...res.data.campaigns]);

      if (res.data.campaigns[0]) {
        if (currentPage === 1) {
          campaignPerformance(res.data.campaigns[0]);
          setSelectedCampaign([res.data.campaigns[0]]);
          setcampaignName(res.data?.campaigns[0]?.name);
        } else {
          setloader(false);
        }
        setLoadingList(false);
        setPages({
          current_page: res?.data?.current_page,
          total_count: res?.data?.total_campaigns,
          total_pages: res?.data?.total_pages,
          last_page: res.data.campaigns.length < 1 && true,
        });
      } else {
        // toast.error('Campaign not found')
        setloader(false);
        setLoadingList(false);
      }
    });
  };
  const QuaterCheck = (date: string) => {
    const month = date.split('-')[1];
    const checkQuater = parseFloat(month) / 3;
    return Math.round(checkQuater);
  };
  // QuaterCheck()

  const campaignPerformance = (selectedCamp: undefined | any) => {
    let campaignId: number;
    // if (selectedCamp) {
    //   campaignId = selectedCamp.id
    // } else {
    //   campaignId = selectedCampaign[0].id
    // }
    setsubmitList(true);
    getCompaignPerformance(selectedCamp.id).then(reds => {
      if (currentPage === 1) {
        setloader(false);
      }
      setsubmitList(false);
      setcampaignName(selectedCamp.name);
      if (Array.isArray(reds.data) && reds.data.length) {
        const multiDataddd = {
          labels: [
            ``,
            `Q${QuaterCheck(reds.data.Q1.end_date)} - ${reds.data.Q1.end_date
              .split('-')[0]
              .slice(-2)}’`,
          ],
          datasets: [
            {
              label: 'Clicks',
              data: [
                `${reds.data.Q0.clicks}`,
                `${reds.data.Q1.clicks}`,
                `${reds.data.Q2.clicks}`,
                `${reds.data.Q3.clicks}`,
              ],
              backgroundColor: '#44C2E6',
              hoverBackgroundColor: '#44C2E6',
            },
            {
              label: 'Impressions',
              data: [
                `${reds.data.Q0.impressions}`,
                `${reds.data.Q1.impressions}`,
                `${reds.data.Q2.impressions}`,
                `${reds.data.Q3.impressions}`,
              ],
              backgroundColor: '#007B94',
              hoverBackgroundColor: '#007B94',
            },
          ],
        };
        setbarData(multiDataddd);
        setIsModalOpen(false);
      } else {
        // toast.error('Campaign not found');
      }
    });
  };

  const listInnerRef = useRef<HTMLInputElement | null>(null);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!pages.last_page) {
          setTimeout(() => {
            setCurrentPage(currentPage + 1);
          }, 300);
        }
      }
    }
  };
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top' as const,
  //     },
  //     title: {
  //       display: true,
  //       text: 'Chart.js Bar Chart',
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };
  //
  // const dataSet = [
  //   {
  //     clicks: 2,
  //     impressions: 3,
  //     ratio: 4,
  //     start_date: new Date('2022/01/11'),
  //     end_date: new Date('2022/10/11'),
  //   },
  //   {
  //     clicks: 50,
  //     impressions: 10,
  //     ratio: 12,
  //     start_date: new Date('2023/04/11'),
  //     end_date: new Date('2032/10/11'),
  //   },
  //   {
  //     clicks: 15,
  //     impressions: 20,
  //     ratio: 10,
  //     start_date: new Date('2012/01/11'),
  //     end_date: new Date('2022/10/11'),
  //   },
  // ];
  //
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  //
  // const data = {
  //   labels: dataSet.map(
  //     dates =>
  //       `${moment(dates.start_date).format('DD/MM/YYYY')} - ${moment(dates.end_date).format(
  //         'DD/MM/YYYY'
  //       )}`
  //   ),
  //   datasets: [
  //     {
  //       label: 'Clicks',
  //       data: dataSet.map(d => d.clicks),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //       // parsing: {
  //       //   yAxisKey: 'clicks',
  //       // },
  //     },
  //     {
  //       label: 'Impressions',
  //       data: dataSet.map(d => d.impressions),
  //       backgroundColor: 'rgba(75, 192, 192, 0.5)',
  //       // parsing: {
  //       //   yAxisKey: 'impressions',
  //       // },
  //     },
  //     {
  //       label: 'Ratio',
  //       data: dataSet.map(d => d.ratio),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //       // parsing: {
  //       //   yAxisKey: 'ratio',
  //       // },
  //     },
  //   ],
  // };
  return (
    <>
      {loader && (
        <div className="CampaignPerformance__loader_campain">
          <Loading />
        </div>
      )}
      {!loader && campaigns.length > 0 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          sub_title={campaignName}
          actions={
            <Button
              kind="primary"
              onClick={() => setIsModalOpen(true)}
              UNSAFE_className="CampaignPerformance__select_campaign"
              disabled={
                campaignName === '' || userIsDeactivatedWithActiveOrderlines || userIsFailed
              }
            >
              Select Campaign
            </Button>
          }
        >
          {isModalOpen && (
            <Modal offClick={() => setIsModalOpen(false)}>
              <div className="OnboardingModal-welcome CampaignPerformance_ListingModalBg CampaignPerformance__submitModal">
                <div className="SaveAudienceModal__cancel">
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="#6d6d6d"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  />
                </div>
                <div
                  // style={{ width: '620px', height: '90%' }}
                  className="CampaignPerformance__content"
                >
                  <div className="">
                    <div style={{ padding: '8px 5rem', textAlign: 'center' }}>
                      <Text
                        on="white"
                        size="xxxl"
                        UNSAFE_className="CampaignPerformance__contentModal"
                        textAlign="center"
                      >
                        Select Campaign{' '}
                      </Text>
                    </div>
                  </div>

                  <div
                    className="Modal__scrollable-content"
                    style={{
                      maxHeight: '445px',
                      overflowY: 'auto',
                      marginBottom: '1rem',
                    }}
                    onScroll={() => onScroll()}
                    ref={listInnerRef}
                  >
                    <Table
                      noCheckbox
                      rows={campaigns}
                      onSelect={camp => {
                        setSelectedCampaign(camp);
                        // setSelections([...id])
                        // setPreSelections([...id])
                      }}
                      radioButton
                      // preSelected={PreSelections}
                      unSelectAll={unselect}
                      // noCheckbox={false}
                      // fixedCellSize
                      columns={[
                        {
                          path: 'name',
                          label: 'Campaign Name',
                          width: '50%',
                          alignment: 'left',

                          // onSort,
                        },

                        {
                          path: 'created_at',
                          label: 'Date Captured',
                          alignment: 'left',
                          width: '20%',
                          RowCell: campaign => (
                            <div
                              style={{
                                // paddingInline: '50px',
                                textAlign: 'start',
                              }}
                            >
                              {' '}
                              {getLocalDateTime(campaign.created_at, true).split(' ')[0]}
                              <br />{' '}
                              {getLocalDateTime(campaign.created_at, true).split(' ')[1]
                                ? getLocalDateTime(campaign.created_at, true).split(' ')[1]
                                : getLocalDateTime(campaign.created_at, true).split(' ')[2]}
                            </div>

                            // || '-'
                          ),
                          // onSort,
                        },
                      ]}
                    />
                    {LoadingList && <Spinner />}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '8px',
                      position: 'absolute',
                      bottom: '3rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      kind="primary"
                      size="xl"
                      width="204px"
                      disabled={submitList}
                      weight="bold"
                      onClick={() => {
                        campaignPerformance(selectedCampaign[0]);
                        // setOpenSubmitModal(false)
                        // History.push('/campaigns')
                      }}
                      loading={submitList}
                    >
                      {submitList ? '' : 'Done'}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          <div className="CampaignDashboard__campaign-performance">
            <Bar data={barData} options={multiOverlappingOptions} height={100} />
          </div>
        </Section>
      )}

      {!loader && campaigns.length < 1 && (
        <Section
          title="Campaign Performance"
          UNSAFE_className_text="CampaignPerformance__title"
          spacer={false}
        >
          <div className="noData">
            <img src={nodata} alt="nodata" />
            <Text on="tertiary-300" UNSAFE_className="noDataText">
              No Data Found
            </Text>
          </div>
        </Section>
      )}
    </>
  );
};
