import { BACKEND_HOST } from 'canva-integration/config';
import request from 'canva-integration/request';

import type {
  CreateAssetUploadJobResponse,
  CreateDesignAutofillJobRequest,
  CreateDesignExportJobRequest,
  CreateDesignExportJobResponse,
  CreateDesignRequest,
  ExportFormat,
  GetAssetUploadJobResponse,
  GetBrandTemplateDatasetResponse,
  GetDesignAutofillJobResponse,
  GetDesignExportJobResponse,
  GetDesignResponse,
  ListBrandTemplatesResponse,
  UserProfileResponse,
  CreateDesignResponse,
} from 'canva-integration/type';
import type {
  DownloadExportedDesignRequest,
  DownloadExportedDesignResponse,
  GetProductsResponse,
  ProductAutofillDataset,
  UpsertProductDesignRequest,
  UpsertProductDesignResponse,
} from 'canva-integration/models';
import { GetDesignsListResponse } from 'canva-integration/type';

const endpoints = {
  AUTHORIZE: '/authorize',
  REVOKE: '/revoke',
  CREATE_ASSET_UPLOAD_JOB: '/asset-uploads',
  GET_ASSET_UPLOAD_JOB: '/asset-uploads/:jobId',
  CREATE_AUTOFILL_JOB: '/autofill/create',
  GET_AUTOFILL_JOB_BY_ID: '/autofill/get/:jobId',
  BRAND_TEMPLATES: '/brand-templates',
  DESIGNS: '/design',
  GET_DESIGN: '/design/:designId',
  GET_DESIGN_EXPORT_FORMATS: '/design/:designId/export-formats',
  PRODUCTS: '/products',
  UPSERT_PRODUCT_DESIGN: '/products/:productId/design',
  USER: '/user/me',
  CREATE_DESIGN_EXPORT_JOB: '/export/generate',
  GET_DESIGN_EXPORT_JOB: '/export/:jobId',
  DOWNLOAD_EXPORT: '/exports/download',
};

export const getUser = async (): Promise<UserProfileResponse> => {
  return request('GET', endpoints.USER);
};

export const getDesigns = async (): Promise<GetDesignsListResponse> =>
  request('GET', endpoints.DESIGNS);

export const getDesignExportFormats = async (id: string): Promise<unknown> =>
  request('GET', endpoints.GET_DESIGN_EXPORT_FORMATS.replace(':designId', id));

export const getDesign = async (id: string): Promise<GetDesignResponse> => {
  return request('GET', endpoints.GET_DESIGN.replace(':designId', id));
};

export const getProducts = async (): Promise<GetProductsResponse> => {
  return request('GET', endpoints.PRODUCTS);
};

export const getAssetUploadJob = async (id: string): Promise<GetAssetUploadJobResponse> => {
  return request('GET', endpoints.GET_ASSET_UPLOAD_JOB.replace(':jobId', id));
};

export const getBrandTemplates = async (): Promise<ListBrandTemplatesResponse> => {
  return request('GET', endpoints.BRAND_TEMPLATES);
};

export const getBrandTemplateDataset = async (
  id: string
): Promise<GetBrandTemplateDatasetResponse> => {
  return request('GET', `${endpoints.BRAND_TEMPLATES}/${id}/dataset`);
};

export const getAutofillJobStatus = async (
  jobId: string
): Promise<GetDesignAutofillJobResponse> => {
  return request('GET', endpoints.GET_AUTOFILL_JOB_BY_ID.replace(':jobId', jobId));
};

export const getDesignExportJobStatus = async (
  jobId: string
): Promise<GetDesignExportJobResponse> => {
  return request('GET', endpoints.GET_DESIGN_EXPORT_JOB.replace(':jobId', jobId));
};

export const postAutofill = async (
  id: string,
  data: ProductAutofillDataset
): Promise<GetDesignAutofillJobResponse> => {
  const body: CreateDesignAutofillJobRequest = {
    brand_template_id: id,
    data,
  };

  return request('POST', endpoints.CREATE_AUTOFILL_JOB, body);
};

export const createDesign = async ({
  assetId,
  title,
}: {
  assetId: string;
  title: string;
}): Promise<CreateDesignResponse> => {
  const body: CreateDesignRequest = {
    design_type: undefined,
    asset_id: assetId,
    title,
  };

  return request('POST', endpoints.DESIGNS, body);
};
export const upsertProductDesign = async ({
  productId,
  productDesign,
}: UpsertProductDesignRequest): Promise<UpsertProductDesignResponse> => {
  return request(
    'PUT',
    endpoints.UPSERT_PRODUCT_DESIGN.replace(':productId', productId.toString()),
    productDesign
  );
};

export const downloadExportedImage = async ({
  exportedDesignUrl,
  productId,
}: DownloadExportedDesignRequest): Promise<DownloadExportedDesignResponse> => {
  return request('POST', endpoints.DOWNLOAD_EXPORT, {
    exportedDesignUrl,
    productId,
  });
};

export const createAssetUpload = async ({
  name,
  image,
}: {
  name: string;
  image: Blob;
}): Promise<CreateAssetUploadJobResponse | undefined> => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('name', name);

  return request('POST', endpoints.CREATE_ASSET_UPLOAD_JOB, formData);
};

export const createDesignExportJob = async ({
  designId,
}: // exportFormat,
{
  designId: string;
  // exportFormat: ExportFormat;
}): Promise<CreateDesignExportJobResponse> => {
  const body: Pick<CreateDesignExportJobRequest, 'designId'> = {
    designId,
    // format: exportFormat,
  };

  return request('POST', endpoints.CREATE_DESIGN_EXPORT_JOB, body);
};
