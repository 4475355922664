import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import Tippy from '@tippyjs/react';

import type { Column } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowFromBottom, faBadgeCheck, faMagic, faSync } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import { EmptyStatus, PageHeader, ReactTable, ReactTableSearchBar } from 'Components';
import { UploadCSVModal } from 'Pages/MyContacts/components';
import UpgradeModal from 'Pages/MyContacts/components/UpgradeModal';
import AddressesAccessor from 'Pages/ProspectActivity/lenses/TalkToSphereLens/TalkToSphereList/AddressesAccessor';
import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget';
import RedirectToMobileInfo from 'Pages/MyContacts/components/RedirectToMobileInfo/RedirectToMobileInfo';
import { VotingModal } from 'Components/VotingModal';

import contactsImporting from 'assets/Images/contacts-loading.gif';
import Uploadmodalcontacts from 'assets/Images/uploadmodalcontacts.png';
import Syncontacts from 'assets/Images/syncontacts.png';

import { deleteContacts, getContacts } from 'Requests';
import { uploadContactsSync } from 'Requests/Request_Methods/contactsMethods';

import { removeTimeFromDate } from 'Helpers';

import type { TResPagination, TContact, TRootState } from 'types';

import './MyContacts.scss';
import { GuideEnum } from 'enums';

export const MyContactsPage = () => {
  const { setGlobalFilters } = useSelector((state: TRootState) => state.setGlobalFilters);
  const { globalFilters } = useSelector((state: TRootState) => state.globalFilters);
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const tableRef = useRef<any>(null);

  const [contacts, setContacts] = useState<TContact[]>([]);
  const [uploadCSVModalIsOpen, setUploadCSVModalIsOpen] = useState(false);
  const history = useHistory();
  const [uploadcontactModal, setUploadcontactModal] = useState(false);
  const [synUploadModal, setSynUploadModal] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  const [buttonActive, setButtonActive] = useState(true);
  const [loadFirst, setloadFirst] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });
  const [enrichState, setEnrichState] = useState({
    enrichment_percent: 0,
    pending_contacts_count: 0,
  });

  // ========== New Table Utilities
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);

  const _fetchData = async (): Promise<void> => {
    setFetchDataIsLoading(true);
    try {
      const res = await getContacts(page, 50);
      if (res.data?.contacts) {
        const {
          total_count,
          total_pages,
          enrichment_percent,
          pending_contacts_count,
          contacts: resContacts,
        } = res.data;

        if (resContacts.length) {
          const guideBotContacts = sessionStorage.getItem('guideBotContacts');
          const contactsBotData = guideBotContacts ? JSON.parse(guideBotContacts) : null;
          if (contactsBotData?.type === GuideEnum.CONTACTS)
            sessionStorage.removeItem('guideBotContacts');
        }

        setContacts(resContacts);
        setPagination({ total_count, total_pages });
        setEnrichState({ enrichment_percent, pending_contacts_count });
      }
      setFetchDataIsLoading(false);
    } catch (err: any) {
      toast.error(err.detail);
      setFetchDataIsLoading(false);
    }
  };

  useEffect(() => {
    _fetchData();
  }, [page]);

  useEffect(() => {
    return () => {
      setContacts([]);
      setPagination({ total_count: 0, total_pages: 0 });
      setEnrichState({ enrichment_percent: 0, pending_contacts_count: 0 });
    };
  }, []);

  const onViewDetail = (item: any) => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <FontAwesomeIcon
          className="contact-link-icon"
          onClick={() =>
            history.push({
              pathname: `/my-sphere/${item.id}/contact-details`,
            })
          }
          icon={faChevronRight}
          size="1x"
          color="gray"
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  };

  const columns: Column<TContact>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'First Name',
          accessor: 'first_name',
          Cell: ({ row: { original: contact } }) =>
            contact.first_name ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {contact.enrichment_data && (
                  <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" style={{ fontSize: 15 }} />
                )}
                <span>{contact.first_name}</span>
              </div>
            ) : (
              <>-</>
            ),
        },
        {
          Header: 'Last Name',
          accessor: contact => contact.last_name || '-',
        },
        {
          Header: 'Email Address',
          accessor: contact => contact.email || '-',
        },
        {
          Header: 'Address',
          accessor: 'addresses',
          Cell: ({ row: { original: contact } }) => (
            <AddressesAccessor addresses={contact.addresses} />
          ),
        },
        {
          Header: 'Date Updated',
          accessor: contact => (contact.updated_at ? removeTimeFromDate(contact.updated_at) : '-'),
        },
        {
          Header: '',
          id: 'link',
          accessor: onViewDetail,
        },
      ],
    },
  ];
  // ========== New Table Utilities

  const onDelete = async (_items: any) => {
    setDeleteIsLoading(true);
    const ids = _items.map((item: any) => item?.original?.id);
    try {
      await deleteContacts({ ids });
      toast.success(`${ids.length} Contact${ids.length > 1 ? 's' : ''} Deleted`);

      if (pagination.total_count - ids.length === 0) {
        sessionStorage.setItem(
          'guideBotContacts',
          JSON.stringify({
            isActive: true,
            type: GuideEnum.CONTACTS,
            msgType: '',
            count: 0,
          })
        );
      }

      setDeleteModalIsOpen(false);
      setDeleteIsLoading(false);
      if (tableRef && tableRef.current) tableRef?.current?.resetSelectedRows();
      if (page === 1) _fetchData();
      else await setPage(1);
    } catch (err: any) {
      toast.error(err.detail);
      setDeleteIsLoading(false);
    }
  };

  const launchCloudSponge = () => {
    // @ts-ignore
    if (window.cloudsponge) {
      // @ts-ignore
      window.cloudsponge.launch();
    }
  };

  const emptyText = useMemo(() => {
    const heading = enrichState.pending_contacts_count
      ? userData?.sub_name === 'premium'
        ? 'We are enriching your contacts!'
        : 'We are importing your contacts!'
      : 'You still do not have any contacts here';

    const subHeading = enrichState.pending_contacts_count
      ? 'Please refresh after a while to see your customers’ list.'
      : 'Start by uploading or syncing your contacts list.';

    return (
      <EmptyStatus
        icon={
          !!enrichState.pending_contacts_count && <img src={contactsImporting} alt="loading..." />
        }
        heading={heading}
        subHeading={subHeading}
      >
        <FlexBox
          flexDirection="column"
          alignItems="center"
          UNSAFE_style={{ gap: '10px', marginBlockStart: '20px' }}
        >
          {/* for proccesing state */}
          {enrichState.pending_contacts_count ? (
            <Button
              size="l"
              kind="text"
              UNSAFE_className="refresh-contacts"
              onClick={() => {
                history.go(0);
              }}
            >
              Refresh
            </Button>
          ) : (
            <>
              <Button
                kind="primary"
                size="l"
                onClick={launchCloudSponge}
                disabled={buttonActive || syncIsLoading}
                iconLeft={
                  buttonActive || syncIsLoading ? (
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faSync} style={{ fontSize: 20 }} />
                  )
                }
              >
                Sync Email Accounts
              </Button>
              <Button kind="text" size="l" onClick={() => setUploadCSVModalIsOpen(true)}>
                Upload Contacts
              </Button>
            </>
          )}
        </FlexBox>
      </EmptyStatus>
    );
  }, [enrichState, userData, syncIsLoading, buttonActive]);

  const afterSubmitContacts = async (cloudContacts: any) => {
    setSyncIsLoading(true);
    try {
      const contactsBody = cloudContacts.map((contact: any) => ({
        first_name: contact.first_name,
        last_name: contact.last_name,
        phone: contact.phone.map((phone: any) => ({
          number: phone.number,
          primary: phone?.primary,
          selected: phone?.selected,
        })),
        email: contact.email.map((email: any) => ({
          address: email.address,
          primary: email?.primary,
          selected: email?.selected,
        })),
        address: contact.address.map((address: any) => ({
          street: address.street,
          city: address.city,
          region: address.region,
          postal_code: address.postal_code,
        })),
      }));
      await uploadContactsSync(contactsBody);
      setSyncIsLoading(false);
      sessionStorage.setItem(
        'guideBotContacts',
        JSON.stringify({
          isActive: false,
        })
      );
      _fetchData();
    } catch (e) {
      setSyncIsLoading(false);
      toast.error('Oops, there was an issue with importing your contacts.');
    }
  };

  return (
    <div className="MyContacts">
      {/* Header Section */}
      <FlexBox flexDirection="column" UNSAFE_style={{ gap: '16px' }}>
        {!!contacts?.length &&
          userData?.sub_name !== 'premium' &&
          !!enrichState.enrichment_percent && (
            <PageHeader
              UNSAFE_CLASSNAME="MyContacts__PageHeader upgrade"
              subTitle={
                <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
                  <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" className="enriched-badge" />
                  <span className="enrich-text">
                    Enrich <b>{enrichState.enrichment_percent}%</b> of your potential leads to run
                    more effective campaigns.
                  </span>
                </FlexBox>
              }
              actions={[
                <Button
                  key="upgrade"
                  UNSAFE_className="upgrade-button"
                  size="l"
                  weight="bold"
                  onClick={() => history.push('/profile/billing')}
                  iconLeft={<FontAwesomeIcon icon={faMagic} />}
                >
                  Upgrade
                </Button>,
              ]}
            />
          )}
        <PageHeader
          UNSAFE_CLASSNAME="MyContacts__PageHeader"
          subTitle={
            <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
              <span>
                {enrichState.pending_contacts_count ? (
                  <b>
                    {userData?.sub_name === 'premium'
                      ? 'Enriching Your Contacts...'
                      : 'Importing Your Contacts...'}
                  </b>
                ) : (
                  <>
                    You have <b>{pagination.total_count} contacts</b>
                  </>
                )}
              </span>
              {!!contacts?.length &&
                !!enrichState.enrichment_percent &&
                userData?.sub_name === 'premium' && (
                  <div className="enrich-tag">
                    <FontAwesomeIcon icon={faBadgeCheck} color="#FCD917" style={{ fontSize: 20 }} />
                    <span className="enrich-text">{enrichState.enrichment_percent}% enriched</span>
                  </div>
                )}
            </FlexBox>
          }
          actions={[
            <ReactTableSearchBar
              key="campaign-searchbar"
              globalFilter={globalFilters}
              setGlobalFilter={setGlobalFilters}
              placeholder="Search by first name, last name"
            />,
            ...(contacts?.length || !!enrichState.pending_contacts_count
              ? [
                  <Tippy
                    key="sync-email-accounts"
                    placement="top"
                    className="audience-pending-tooltip"
                    trigger="mouseenter"
                    content="Sync email accounts"
                  >
                    <Button
                      UNSAFE_className="sync-email-accounts"
                      kind="primary"
                      size="l"
                      disabled={
                        !!enrichState.pending_contacts_count || buttonActive || syncIsLoading
                      }
                      onClick={launchCloudSponge}
                      iconLeft={
                        buttonActive || syncIsLoading ? (
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            style={{
                              fontSize: 20,
                              animation: 'spinner-border 0.75s linear infinite',
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faSync} />
                        )
                      }
                    />
                  </Tippy>,
                  <Tippy
                    key="upload-contacts"
                    placement="top-end"
                    className="audience-pending-tooltip"
                    trigger="mouseenter"
                    content="Upload contacts"
                  >
                    <Button
                      UNSAFE_className="upload-contacts"
                      kind="primary"
                      size="l"
                      disabled={!!enrichState.pending_contacts_count}
                      onClick={() => setUploadCSVModalIsOpen(true)}
                      iconLeft={<FontAwesomeIcon icon={faArrowFromBottom} />}
                    />
                  </Tippy>,
                ]
              : []),
          ]}
        />
        {!!enrichState.pending_contacts_count && !!contacts?.length && (
          <PageHeader
            UNSAFE_CLASSNAME="MyContacts__PageHeader pending_contacts"
            subTitle={
              <FlexBox alignItems="center" UNSAFE_style={{ gap: '15px' }}>
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  color="#007B94"
                  className="contact-spinner"
                />
                <FlexBox flexDirection="column">
                  <Text
                    on="white"
                    UNSAFE_style={{ fontSize: '14px' }}
                    UNSAFE_className="processing-title"
                  >
                    {userData?.sub_name === 'premium'
                      ? 'We are enriching your contacts!'
                      : 'We are importing your contacts!'}
                  </Text>
                  <Text
                    on="white"
                    UNSAFE_style={{ fontSize: '12px' }}
                    UNSAFE_className="processing-description"
                  >
                    Please refresh after a while to see uploaded list.
                  </Text>
                </FlexBox>
              </FlexBox>
            }
            actions={[
              <Button
                key="refresh"
                size="l"
                kind="text"
                UNSAFE_className="refresh-contacts"
                onClick={() => {
                  history.go(0);
                }}
              >
                Refresh
              </Button>,
            ]}
          />
        )}
        {upgradeModal && (
          <Modal offClick={() => setUpgradeModal(false)}>
            <UpgradeModal
              onCancel={() => setUpgradeModal(false)}
              title={
                <span>
                  Enrich <b>90%</b> of your 240 Contacts
                </span>
              }
              description="Unlock the full potential of your contacts with enriched data. You will gain deeper insights into your contacts, enabling you to tailor your interactions and run more effective campaigns."
            />
          </Modal>
        )}
        {uploadcontactModal && (
          <Modal offClick={() => setUploadcontactModal(false)}>
            <VotingModal
              handleModel={() => setUploadcontactModal(false)}
              icons={Uploadmodalcontacts}
              title="Upload Contacts"
              subTitle="Upload or enter your contacts to receive notifications when your contacts are home shopping and activate advertising."
            />
          </Modal>
        )}
        {synUploadModal && (
          <Modal offClick={() => setSynUploadModal(false)}>
            <VotingModal
              handleModel={() => setSynUploadModal(false)}
              icons={Syncontacts}
              title="Sync Contacts"
              subTitle="Sync email contacts to receive notifications when your contacts are home shopping and activate advertising."
            />
          </Modal>
        )}
      </FlexBox>
      <ReactTable<TContact>
        ref={tableRef}
        loading={fetchDataIsLoading}
        emptyText={emptyText}
        title="contact"
        pageSize={50}
        setPage={setPage}
        currentPage={page}
        pageCount={pagination.total_pages}
        totalCount={pagination.total_count}
        data={contacts}
        columns={columns}
        onDelete={onDelete}
        deleteModal={deleteModalIsOpen}
        setDeleteModal={setDeleteModalIsOpen}
        deleteIsLoading={deleteIsLoading}
      />
      {!contacts.length && <RedirectToMobileInfo />}
      {uploadCSVModalIsOpen && (
        <UploadCSVModal
          onClose={(e?: boolean) => {
            if (e) {
              _fetchData();
            }
            setUploadCSVModalIsOpen(false);
          }}
        />
      )}
      <CloudSpongeWidget
        afterSubmitContacts={afterSubmitContacts}
        setButtonActive={setButtonActive}
        loadFirst={loadFirst}
        buttonActive={buttonActive}
        onCloseTypea={() => {
          setloadFirst(false);
        }}
      />
    </div>
  );
};
