/* eslint-disable */
import React, { ChangeEvent, useMemo, useState } from 'react';
import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import { Form, Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import { ErrorMsg, Section } from 'Components';
import { LabelInputField } from 'Components/LabelInputField';
import { LabelInputSelect } from 'Components/LabelInputSelect';
import { ManageAvatar } from 'Pages/MyProfile/components/ManageAvatar';

import { useForm, useValidatedState } from '@eltoro-ui/hooks';
import type { ProfilePersonalInfoType } from 'types';

import Leftvoting from 'assets/Images/leftvoting.svg';
import Rightvoting from 'assets/Images/rightvoting.svg';
import cross from 'assets/Images/cross.png';

import USStates from 'Utils/us_states.json';

import { updateTheUser, userDetail } from 'Requests';
import { setUser } from 'Redux/actions';
import { userRole } from 'Helpers';
import { usePermissionContext } from 'contexts/PermissionContext';
import type { ErrorResponse, FieldErrors, UserInfoType } from 'types';

import './PersonalInfoSection.scss';

type PersonalInfoSectionType = {
  user: ProfilePersonalInfoType;
};

export const PersonalInfoSection: FC<PersonalInfoSectionType> = ({ user }) => {
  const dispatch = useDispatch();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [loadingImg, setloadingImg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const [resErrorPhoneNumber, setResErrorPhoneNumber] = useState('');
  const [fullScreenImage, setfullScreenImage] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidZipCode, setIsValidZipCode] = useState(true);
  const [isValidBillingZipCode, setIsValidBillingZipCode] = useState(true);
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const [errors, setErrors] = useState<FieldErrors<UserInfoType['personalInfo']>>({});

  const [personalInfo, setPersonalInfo] = useState<ProfilePersonalInfoType>({
    first_name: '',
    last_name: '',
    brokerage: '',
    email: '',
    mls_id: '',
    mls_registered_name: '',
    phone_number: '',
    profile_pic: '',
    primary_zip_code: '',
    state: '',
    street: '',
    city: '',
    billing_zip_code: '',
  });

  const [, setEmail, isValidEmail] = useValidatedState(personalInfo.email, 'email');
  const { isValidForm, touched } = useForm<ProfilePersonalInfoType>({
    ...personalInfo,
  });
  const required = [
    'first_name',
    'last_name',
    'mls_id',
    'mls_registered_name',
    'phone_number',
    'brokerage',
    'email',
    'primary_zip_code',
    'state',
    'street',
    'city',
    'billing_zip_code',
  ];

  useMemo(() => {
    if (user) {
      const {
        mls_id,
        mls_registered_name,
        phone_number,
        brokerage,
        email,
        primary_zip_code,
        profile_pic,
        first_name,
        last_name,
        state,
        street,
        city,
        billing_zip_code,
      } = user || {};

      setPersonalInfo({
        ...personalInfo,
        profile_pic,
        mls_id,
        mls_registered_name,
        brokerage,
        email,
        primary_zip_code,
        first_name,
        last_name,
        state,
        street,
        city,
        billing_zip_code,
        phone_number: phone_number ?? '',
      });
    }
  }, [user]);
  const { validForm, missingFields } = isValidForm([...required]);

  const validateZipCode = (primary_zip_code: string) =>
    /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(primary_zip_code);

  const onSubmit = () => {
    setLoading(true);

    const {
      mls_id,
      mls_registered_name,
      phone_number,
      brokerage,
      email,
      primary_zip_code,
      first_name,
      last_name,
      state,
      street,
      city,
      billing_zip_code,
    } = personalInfo;
    updateTheUser({
      mls_id,
      mls_registered_name,
      phone_number,
      brokerage,
      email,
      primary_zip_code,
      first_name,
      last_name,
      state,
      street,
      city,
      billing_zip_code,
    })
      .then(() => {
        const token = localStorage.getItem('beewo_token') || undefined;

        userDetail(token).then(res => {
          setLoading(false);
          const _user = {
            user_role: userRole(res.data?.is_super),
            ...res.data,
          };
          dispatch(setUser(_user));
          toast.success('Saved Successfully!');
          setLoading(false);
        });
      })
      .catch((err: ErrorResponse<UserInfoType['personalInfo']>) => {
        if (Array.isArray(err.detail))
          err.detail.map(error =>
            setErrors(errors => ({
              ...errors,
              [error.loc[1]]: {
                type: error.type,
                message: error.msg,
              },
            }))
          );
        else toast.error(err.detail);
        setLoading(false);
      });
  };

  const onChange = (key: keyof typeof personalInfo, value: string | undefined) => {
    setPersonalInfo({ ...personalInfo, [key]: value });
  };

  const isDisabled = useMemo(() => {
    const {
      mls_id,
      mls_registered_name,
      phone_number,
      brokerage,
      email,
      primary_zip_code,
      first_name,
      last_name,
      street,
      state,
      city,
      billing_zip_code,
    } = personalInfo;
    return (
      !(
        mls_registered_name &&
        mls_id &&
        primary_zip_code &&
        isValidZipCode &&
        email &&
        brokerage &&
        phone_number &&
        first_name &&
        last_name &&
        street &&
        state &&
        city &&
        billing_zip_code &&
        isValidBillingZipCode &&
        isValidPhoneNumber
      ) ||
      !validForm ||
      !!Object.keys(errors).length ||
      userIsFailed ||
      userIsDeactivatedWithActiveOrderlines
    );
  }, [personalInfo, validForm, errors]);

  return (
    <div className="PersonalInfoSectionContainer">
      <Form
        onSubmit={onSubmit}
        required={required}
        valid={validForm && !Object.keys(errors).length}
        touched={touched}
        missingFields={missingFields}
      >
        <div className="PersonalInfoSectionContainer__header">
          <Text on="white" size="l" UNSAFE_className="PersonalInfoSectionContainer__heading">
            General Info
          </Text>
          <Button
            type="submit"
            kind="primary"
            weight="bold"
            size="l"
            disabled={isDisabled || loading || loadingImg}
            loading={loading}
          >
            Save
          </Button>
        </div>
        <FlexBox
          alignItems="flex-end"
          justifyContent="space-between"
          flexWrap="wrap-reverse"
          UNSAFE_style={{ marginTop: '27px' }}
        >
          <div className="PersonalInfoSection">
            <div className="PersonalInfoSection__form_container">
              <div className="form-section">
                <span className="form-section-title">Personal Information</span>
                <div className="form-section-content">
                  <div className="inputs-wrapper">
                    <div>
                      {/* First Name  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          value={personalInfo.first_name}
                          title="First Name"
                          placeholder="Enter your first name"
                          type="text"
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.first_name)
                              setErrors(({ first_name, ...errors }) => ({ ...errors }));
                            onChange('first_name', e.target.value);
                          }}
                        />
                        {errors?.first_name?.message ? (
                          <ErrorMsg
                            title={errors?.first_name?.message ?? 'Please enter your first name'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                      {/* MLS Registered Name  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          value={personalInfo.mls_registered_name}
                          title="Your MLS Registered Name"
                          placeholder="Enter your MLS registered name"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.mls_registered_name)
                              setErrors(({ mls_registered_name, ...errors }) => ({ ...errors }));
                            onChange('mls_registered_name', e.target.value);
                          }}
                        />
                        {errors?.mls_registered_name?.message ? (
                          <ErrorMsg
                            title={
                              errors?.mls_registered_name?.message ??
                              'Please enter right your MLS registered name'
                            }
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>

                      {/* MLS Agent ID  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          requireTage="*"
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          value={personalInfo.mls_id}
                          title="Your MLS Agent ID"
                          placeholder="e.g. 1223456789"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.mls_id)
                              setErrors(({ mls_id, ...errors }) => ({ ...errors }));
                            onChange('mls_id', e.target.value);
                          }}
                        />
                        {errors?.mls_id?.message ? (
                          <ErrorMsg
                            title={
                              errors?.mls_id?.message ?? 'Please enter right your MLS agent ID'
                            }
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>

                      {/* Work Email  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          requireTage="*"
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          value={personalInfo.email}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Work Email"
                          placeholder="Work Email"
                          type="email"
                          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                            if (errors?.email) setErrors(({ email, ...errors }) => ({ ...errors }));
                            onChange('email', value);
                            setEmail(value);
                          }}
                        />
                        {errors?.email?.message || (!isValidEmail && personalInfo.email !== '') ? (
                          <ErrorMsg
                            title={errors?.email?.message ?? 'Invalid email!'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div>
                      {/* Last Name  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          value={personalInfo.last_name}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Last Name"
                          placeholder="Enter your last name"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.last_name)
                              setErrors(({ last_name, ...errors }) => ({ ...errors }));
                            onChange('last_name', e.target.value);
                          }}
                        />
                        {errors?.last_name?.message ? (
                          <ErrorMsg
                            title={errors?.last_name?.message ?? 'Please enter your last name'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                      {/* Zip Code  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          requireTage="*"
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          value={personalInfo.primary_zip_code}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Primary Zip Code"
                          placeholder="e.g. 12345"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.primary_zip_code)
                              setErrors(({ primary_zip_code, ...errors }) => ({ ...errors }));
                            onChange('primary_zip_code', e.target.value);
                            setIsValidZipCode(validateZipCode(e.target.value));
                          }}
                        />
                        {errors?.primary_zip_code?.message ||
                        (!isValidZipCode && personalInfo.primary_zip_code !== '') ? (
                          <ErrorMsg
                            title={errors?.primary_zip_code?.message ?? 'Invalid zip code!'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>

                      {/* Brokerage  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          requireTage="*"
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          value={personalInfo.brokerage}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Your Brokerage"
                          placeholder="Enter your brokerage"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.brokerage)
                              setErrors(({ brokerage, ...errors }) => ({ ...errors }));
                            onChange('brokerage', e.target.value);
                          }}
                        />
                        {errors?.brokerage?.message ? (
                          <ErrorMsg
                            title={errors?.brokerage?.message ?? 'Enter your brokerage'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>

                      {/* Phone Number  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          value={personalInfo.phone_number}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Phone Number"
                          placeholder="e.g. 234567890987"
                          type="text"
                          onChange={e => {
                            resErrorPhoneNumber && setResErrorPhoneNumber('');
                            const value = e.target.value.replace(/[^0-9() -]+/g, '');
                            onChange('phone_number', value);

                            if (value) {
                              try {
                                const number = phoneUtil.parseAndKeepRawInput(value, 'US');
                                setIsValidPhoneNumber(
                                  phoneUtil.isValidNumberForRegion(number, 'US')
                                );
                              } catch (e) {
                                setIsValidPhoneNumber(false);
                              }
                            }
                          }}
                        />
                        {errors?.phone_number?.message ||
                        (!isValidPhoneNumber && personalInfo.phone_number !== '') ? (
                          <ErrorMsg
                            title={errors?.phone_number?.message ?? 'Invalid phone number!'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <span className="form-section-title">Address</span>
                <div className="form-section-content">
                  <div className="inputs-wrapper">
                    <div>
                      {/* Street */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          value={personalInfo.street}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Street"
                          placeholder="Enter your street name"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.street)
                              setErrors(({ street, ...errors }) => ({ ...errors }));
                            onChange('street', e.target.value);
                          }}
                        />
                        {errors?.street?.message ? (
                          <ErrorMsg
                            title={errors?.street?.message ?? 'Please enter your street name'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>

                      {/* State  */}
                      <div className="input-wrapper">
                        <LabelInputSelect
                          requireTage="*"
                          value={personalInfo.state}
                          title="Choose State"
                          Options={
                            <>
                              {USStates.map(state => (
                                <React.Fragment key={state}>
                                  <option
                                    value={state}
                                    disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                                  >
                                    {state}
                                  </option>
                                </React.Fragment>
                              ))}
                            </>
                          }
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            onChange('state', e.target.value);
                          }}
                          selectDisable={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                        />
                        {errors?.state?.message ? (
                          <ErrorMsg
                            title={errors?.state?.message ?? 'Please choose your state'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div>
                      {/* City  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          requireTage="*"
                          value={personalInfo.city}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="City"
                          placeholder="Enter your city name"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.city) setErrors(({ city, ...errors }) => ({ ...errors }));

                            onChange('city', e.target.value);
                          }}
                        />
                        {errors?.city?.message ? (
                          <ErrorMsg
                            title={errors?.city?.message ?? 'Please enter your city name'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                      {/* Zip Code  */}
                      <div className="input-wrapper">
                        <LabelInputField
                          requireTage="*"
                          style={{ paddingLeft: '22px' }}
                          lableStyle={{ left: '14px' }}
                          value={personalInfo.billing_zip_code}
                          disabled={userIsDeactivatedWithActiveOrderlines || userIsFailed}
                          title="Zip Code"
                          placeholder="e.g. 45678"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (errors?.billing_zip_code)
                              setErrors(({ billing_zip_code, ...errors }) => ({ ...errors }));
                            onChange('billing_zip_code', e.target.value);
                            setIsValidBillingZipCode(validateZipCode(e.target.value));
                          }}
                        />
                        {errors?.billing_zip_code?.message ||
                        (!isValidBillingZipCode && personalInfo.billing_zip_code !== '') ? (
                          <ErrorMsg
                            title={errors?.billing_zip_code?.message ?? 'Invalid zip code!'}
                            icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <div>
                <div className="PersonalInfoSectionContainer__reset_password_container">
                  <button className="PersonalInfoSectionContainer__reset_password" type="button">
                    <NavLink to="/profile/password-reset">Reset Password</NavLink>
                  </button>
                </div>
              </div>
            </footer>
          </div>
          <div className="PersonalInfoSection__avatar">
            <ManageAvatar
              userName={`${user?.first_name} ${user?.last_name}`}
              url={personalInfo?.profile_pic}
              loading={loadingImg}
              setLoading={setloadingImg}
              onClick={() => {
                personalInfo?.profile_pic ? setfullScreenImage(true) : '';
              }}
            />
          </div>
        </FlexBox>
      </Form>
      {fullScreenImage ? (
        <Modal
          offClick={() => {
            setfullScreenImage(false);
          }}
        >
          <div className="imageModal">
            <img
              src={cross}
              onClick={() => {
                setfullScreenImage(false);
              }}
              alt="cross"
              className="PersonalInfoForm__cross"
              role="presentation"
            />
            <img className="leftvoting_icon1" src={Leftvoting} alt="icon" />
            <img className="profile-pic" src={personalInfo?.profile_pic} alt="" />

            <img className="rightvoting_icon1" src={Rightvoting} alt="icon" />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
};
