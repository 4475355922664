import type { GetDesignExportJobResponse } from 'canva-integration/type';
import { createDesignExportJob, getDesignExportJobStatus } from 'canva-integration/services';
import { poll } from 'Utils/poll';

/**
 * Create an export and await the result of the export job.
 * @param {Object} options - The options object.
 * @param {string} options.designId - The ID of the design to export.
 * @param {string} options.pageList - The pages of the design to export, by default the first page is exported.
 * @returns {Promise<GetAutofillJobResponse>} A promise that resolves with the autofill job response.
 */
export const exportDesign = async ({
  designId,
}: {
  designId: string;
}): Promise<GetDesignExportJobResponse> => {
  try {
    const exportJobResponse = await createDesignExportJob({
      designId,
    });
    return poll(() => getDesignExportJobStatus(exportJobResponse.job.id));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
