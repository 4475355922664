import React, { ReactNode } from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DeactivateInfoBar.scss';

type DeactivatedAccountInfoBannerProps = {
  heading: string;
  description: string;
  button: ReactNode;
};

const DeactivatedAccountInfoBanner = ({
  heading,
  description,
  button,
}: DeactivatedAccountInfoBannerProps) => {
  return (
    <FlexBox UNSAFE_className="deactivated-info-bar" alignItems="center" gap="12px">
      <FontAwesomeIcon icon={faExclamationCircle} className="info-icon" />
      <div className="info-content">
        <div>
          <div className="heading">{heading}</div>
          <div className="description">{description}</div>
        </div>
        {button}
      </div>
    </FlexBox>
  );
};
export default DeactivatedAccountInfoBanner;
