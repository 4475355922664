import React from 'react';
import { FlexBox } from '@eltoro-ui/components';
import warning from 'assets/Images/warning_icon.svg';
import { UserStatusLoginDenied } from 'enums';

const InfoBarInLoginPage = ({ status }: { status: UserStatusLoginDenied }) => {
  const statusTextHeading = {
    [UserStatusLoginDenied.SUSPENDED]: 'Your account has been suspended',
    [UserStatusLoginDenied.CLOSED]: 'Your account is currently deactivated.',
  };
  return (
    <FlexBox flexDirection="column" gap="8px" alignItems="center" UNSAFE_className="suspendInfo">
      <img src={warning} alt="warning" width={38} height={38} />
      <h2 className="suspendInfo_heading">{statusTextHeading[status]}</h2>
      <div>
        {status === UserStatusLoginDenied.SUSPENDED && (
          <p className="suspendInfo_par">
            Your account has been suspended due to the violation of our{' '}
            <a href="/terms-of-use.pdf" target="_blank" rel="noreferrer">
              Beewo Terms of Service
            </a>
            . All the active campaigns have been paused.
          </p>
        )}
        <p className="suspendInfo_par suspendInfo_par_support">
          {status === UserStatusLoginDenied.SUSPENDED
            ? 'If you believe this is a mistake or need further assistance, please contact our support team at '
            : 'If you need further assistance, please contact our support team at '}
          <a href="mailto:legal@eltoro.com">legal@eltoro.com</a>
        </p>
      </div>
    </FlexBox>
  );
};
export default InfoBarInLoginPage;
