export const subscriptionsBulletPoints = [
  {
    id: 'beewo-subscription-basic',
    bullet_points: [
      {
        name: 'Prospect Lenses',
        description: ['Talk To Your Sphere', 'Virtual Just Listed/Just Sold', 'Sell Your Listing'],
      },
      {
        name: 'Advertising & Performance Reporting',
      },
      {
        name: 'Upload or sync contacts',
      },
      {
        name: 'Notifications about changes in the market and MLS inventory',
      },
      {
        name: 'Receive notification when your contact households are active in the market',
      },
    ],
  },
  {
    id: 'beewo-subscription-premium',
    bullet_points: [
      {
        name: 'Prospect Lenses',
        description: [
          'Talk To Your Sphere',
          'Virtual Just Listed/Just Sold',
          'Sell Your Listing',
          'Grow Your Sphere',
          'Likely Sellers',
        ],
      },
      {
        name: 'Advertising & Performance Reporting',
      },
      {
        name: 'Upload or sync contacts',
      },
      {
        name: 'Notifications about changes in the market and MLS inventory',
      },
      {
        name: 'Receive notification when your contact households are active in the market',
      },
      {
        name: 'Advanced Reporting',
      },
      {
        name: 'Contact Enrichment',
      },
    ],
  },
];

export const TargetsTypes = [
  'AUDIENCE_TYPE_DEVICE',
  'AUDIENCE_TYPE_DC',
  'AUDIENCE_TYPE_B2C',
  'AUDIENCE_TYPE_IP_FOR_ETHASH',
];

export const ComingSoonFeatures = ['Advanced Reporting'];

export const PremiumUserProspects = ['Likely Sellers', 'Grow Your Sphere'];

export const privacyPolicies = [
  {
    title: 'Privacy Policy',
    link: 'https://beewo.com/privacy',
  },
  {
    title: 'Your Privacy Choices and Opt-Out Rights',
    link: 'https://beewo.com/your-privacy-choices/',
  },
  {
    title: 'Do Not Sell or Share My Personal Information',
    link: 'https://beewo.com/do-not-sell-my-personal-information/',
  },
  {
    title: 'Access Deletion Form',
    link: 'https://beewo.com/access-deletion-form/',
  },
];

export const MIN_IMPRESSIONS_COUNT = 1400;
export const VIDEO_CPM = 30;
export const BANNER_CPM = 20;

export const BANNER_FREQ = 4;
export const VIDEO_FREQ = 3;

export const DEFAULT_MIN_FLIGHT_DATES = 7;
export const DEFAULT_SUGGESTED_DATES = 15;
export const DEFAULT_MIN_PROSPECTS_COUNT = 20;

export const SocialMediaType = {
  SOCIAL_TYPE_FACEBOOK: 2,
  SOCIAL_TYPE_TWITTER: 3,
  SOCIAL_TYPE_INSTAGRAM: 4,
  SOCIAL_TYPE_LINKEDIN: 5,
};
