import React, { useState, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { CreativesMutableType } from 'Components/SummaryCart/SummaryEditModal';
import { DEFAULT_MIN_FLIGHT_DATES, MIN_IMPRESSIONS_COUNT } from 'Utils/constants';

import CostItem from './CostItem';

interface Props {
  setIsValid: Dispatch<
    SetStateAction<{
      cost: boolean;
      flightDates: boolean;
      audiences: boolean;
    }>
  >;
  creativesMutableData: CreativesMutableType;
  setCreativesMutableData: Dispatch<SetStateAction<CreativesMutableType>>;
}

const CreativesCost = ({ setIsValid, creativesMutableData, setCreativesMutableData }: Props) => {
  const [creativesIsValid, setCreativesValid] = useState({
    banner: true,
    video: true,
  });

  const getOveralMinDate = () => {
    const { bannerCost, videoCost, prospectsCount, bannerFreq, videoFreq } = creativesMutableData;
    const minDaysBanner = bannerCost ? MIN_IMPRESSIONS_COUNT / (prospectsCount * bannerFreq) : 0;
    const minDaysVideo = videoCost ? MIN_IMPRESSIONS_COUNT / (prospectsCount * videoFreq) : 0;

    return Math.max(Math.ceil(minDaysBanner), Math.ceil(minDaysVideo));
  };

  const handleUpdateMutableData = (updatedCost: number, type: string, frequency: number) => {
    if (type === 'banner') {
      setCreativesMutableData(prev => ({
        ...prev,
        updatedBannerCost: updatedCost,
        bannerFreq: frequency,
      }));
    } else {
      setCreativesMutableData(prev => ({
        ...prev,
        updatedVideoCost: updatedCost,
        videoFreq: frequency,
      }));
    }
  };

  useEffect(() => {
    const minDays = getOveralMinDate();

    setCreativesMutableData(prev => ({
      ...prev,
      minDays: minDays > DEFAULT_MIN_FLIGHT_DATES ? minDays : DEFAULT_MIN_FLIGHT_DATES,
    }));
  }, [
    creativesMutableData.updatedBannerCost,
    creativesMutableData.updatedBannerCost,
    creativesMutableData.bannerFreq,
    creativesMutableData.videoFreq,
    creativesMutableData.prospectsCount,
  ]);

  useEffect(() => {
    setIsValid(prev => ({
      ...prev,
      cost: Object.values(creativesIsValid).every(creative => creative),
    }));
  }, [creativesIsValid]);

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      padding="1.5rem"
      gap="32px"
      UNSAFE_style={{ width: '100%' }}
    >
      {!!creativesMutableData.videoCost && (
        <CostItem
          type="video"
          setCreativesValid={setCreativesValid}
          defaultCost={+creativesMutableData.videoCost.toFixed(2)}
          minDays={creativesMutableData.minDays}
          days={creativesMutableData.days}
          prospectsCount={creativesMutableData.prospectsCount}
          handleUpdateMutableData={handleUpdateMutableData}
          minProspCount={creativesMutableData.minProspCount}
        />
      )}
      {!!creativesMutableData.bannerCost && (
        <CostItem
          type="banner"
          setCreativesValid={setCreativesValid}
          defaultCost={+creativesMutableData.bannerCost.toFixed(2)}
          minDays={creativesMutableData.minDays}
          days={creativesMutableData.days}
          prospectsCount={creativesMutableData.prospectsCount}
          handleUpdateMutableData={handleUpdateMutableData}
          minProspCount={creativesMutableData.minProspCount}
        />
      )}
    </FlexBox>
  );
};

export default CreativesCost;
