import React from 'react';
import { Modal } from '@eltoro-ui/components';
import cross from 'assets/Images/cross.png';
import { PostMediaType } from 'types';

interface PostImagePreviewModalProps {
  media: PostMediaType;
  offClick: () => void;
}
const PostImagePreviewModal = ({ media, offClick }: PostImagePreviewModalProps) => {
  return (
    <Modal className="creative-image-preview">
      <img
        src={cross}
        onClick={offClick}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <div className="header" style={{ justifyContent: 'center' }}>
        <span className="name">{media.name}</span>
      </div>
      <div className="preview-modal-container">
        <img src={media.url} alt={media.type} />
      </div>
    </Modal>
  );
};

export default PostImagePreviewModal;
