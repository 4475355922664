import React, { useCallback, useMemo, useState } from 'react';
import { Modal, FlexBox, Text, Button } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import type { CampaignType, AudienceType } from 'types';
import { DEFAULT_MIN_FLIGHT_DATES, DEFAULT_MIN_PROSPECTS_COUNT } from 'Utils/constants';
import ResultFooter from './components/ResultFooter';
import AdvancedOptions from './components/AdvancedOptions';
import CreativesCost from './components/CreativesCost';
import './SummaryEditModal.scss';

export type CreativesMutableType = {
  bannerCost: number;
  videoCost: number;
  currentDays: number;
  days: number;
  currentProspectsCount: number;
  prospectsCount: number;
  updatedBannerCost: number;
  updatedVideoCost: number;
  endDate: Date;
  startDate: Date;
  minDays: number;
  bannerFreq: number;
  videoFreq: number;
  campaign: CampaignType | null;
  minProspCount: number;
  excludedAudiences: number[];
  changedAudiences: AudienceType[];
};

interface Props {
  onClose: VoidFunction;
  bannerCost: number;
  videoCost: number;
  days: number;
  prospectsCount: number;
  endDate: Date;
  startDate: Date;
  minDays: number;
  bannerFreq: number;
  onConfirm: (updatedData: CreativesMutableType) => void;
  videoFreq: number;
  campaign: CampaignType | null;
  excludedAudiences: number[];
  changedAudiences: AudienceType[];
}

const SummaryEditModal = ({
  onClose,
  videoCost,
  bannerCost,
  days,
  prospectsCount,
  endDate,
  startDate,
  minDays,
  bannerFreq,
  videoFreq,
  campaign,
  onConfirm,
  excludedAudiences,
  changedAudiences: touchedAudiences,
}: Props) => {
  const [isValid, setIsValid] = useState({
    cost: true,
    flightDates: true,
    audiences: true,
  });
  const [creativesMutableData, setCreativesMutableData] = useState<CreativesMutableType>({
    bannerCost,
    videoCost,
    updatedBannerCost: bannerCost,
    updatedVideoCost: videoCost,
    currentDays: days,
    days,
    currentProspectsCount: prospectsCount,
    prospectsCount,
    endDate,
    startDate,
    minDays: minDays > DEFAULT_MIN_FLIGHT_DATES ? minDays : DEFAULT_MIN_FLIGHT_DATES,
    bannerFreq,
    videoFreq,
    campaign,
    minProspCount: DEFAULT_MIN_PROSPECTS_COUNT,
    excludedAudiences,
    changedAudiences: touchedAudiences,
  });

  const cost = useMemo(() => {
    return +(
      creativesMutableData.updatedVideoCost + creativesMutableData.updatedBannerCost
    ).toFixed(2);
  }, [creativesMutableData.updatedBannerCost, creativesMutableData.updatedVideoCost]);

  const handleUpdateAudienceExclusion = (checked: boolean, id: number) => {
    const exclArr = [...creativesMutableData.excludedAudiences];
    let changedArr = [...creativesMutableData.changedAudiences];

    if (!checked) {
      exclArr.push(id);
      changedArr = changedArr.filter(item => item.id !== id);
    } else {
      const foundIndex = exclArr.findIndex(item => item === id);
      exclArr.splice(foundIndex, 1);
    }

    setCreativesMutableData(prev => ({
      ...prev,
      excludedAudiences: exclArr,
      changedAudiences: changedArr,
    }));
  };

  const handleUpdateChangedAudiences = useCallback(
    (id: number, prospCount: number, aud?: AudienceType) => {
      const changedAudArr = [...creativesMutableData.changedAudiences];
      const alreadyWasChangedIndex = changedAudArr.findIndex(item => item.id === id);

      if (changedAudArr[alreadyWasChangedIndex]) {
        changedAudArr[alreadyWasChangedIndex].prospects_counts = prospCount;
      } else {
        changedAudArr.push({
          ...aud,
          prospects_counts: prospCount,
        } as AudienceType);
      }

      setCreativesMutableData(prev => ({
        ...prev,
        changedAudiences: changedAudArr,
      }));
    },
    [creativesMutableData.changedAudiences]
  );

  return (
    <Modal offClick={onClose} closable={false} className="budget_adv_control_modal">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        gap="46px"
        UNSAFE_style={{ width: '825px' }}
        UNSAFE_className="budget_wrapper"
      >
        <Button onClick={onClose} UNSAFE_className="budget_cross">
          <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Button>
        <FlexBox
          flexDirection="column"
          UNSAFE_style={{ width: '100%', position: 'relative' }}
          gap="20px"
        >
          <Text
            on="white"
            weight="bold"
            textAlign="center"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 24, margin: '1.5rem 0 1rem' }}
          >
            Edit Spend
          </Text>
          <FlexBox
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            UNSAFE_style={{ width: '100%', maxHeight: '35rem', overflowY: 'auto' }}
          >
            <CreativesCost
              setIsValid={setIsValid}
              creativesMutableData={creativesMutableData}
              setCreativesMutableData={setCreativesMutableData}
            />
            <AdvancedOptions
              setIsValid={setIsValid}
              handleUpdateChangedAudiences={handleUpdateChangedAudiences}
              handleUpdateAudienceExclusion={handleUpdateAudienceExclusion}
              creativesMutableData={creativesMutableData}
              setCreativesMutableData={setCreativesMutableData}
            />
          </FlexBox>
          <ResultFooter
            cost={+cost}
            onConfirm={() => onConfirm(creativesMutableData)}
            isDisabled={Object.values(isValid).some(item => !item)}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default SummaryEditModal;
