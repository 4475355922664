import React from 'react';

import { Button, FlexBox } from '@eltoro-ui/components';

import access_denied_icon from 'assets/Images/SupportErrorPages/access-denied.svg';
import not_found_icon from 'assets/Images/SupportErrorPages/not-found.svg';
import server_error_icon from 'assets/Images/SupportErrorPages/server-error.svg';

import './SupportErrorPages.scss';
import { usePermissionContext } from 'contexts/PermissionContext';

type SupportErrorPagesProps = {
  type: 'access-denied' | 'not-found' | 'server-error';
};

const SupportErrorPagesIcon = ({ type }: Pick<SupportErrorPagesProps, 'type'>) => {
  if (type === 'access-denied') return <img src={access_denied_icon} alt={type} />;
  if (type === 'not-found') return <img src={not_found_icon} alt={type} />;
  if (type === 'server-error') return <img src={server_error_icon} alt={type} />;

  return null;
};

const SupportErrorPagesMessage = ({ type }: Pick<SupportErrorPagesProps, 'type'>) => {
  if (type === 'access-denied')
    return (
      <span className="message">
        You do not have permission to view this page, or the link may not be available.Please
        contact{' '}
        <a href="mailto:help@beewo.com" className="support-mail">
          help@beewo.com
        </a>{' '}
        for assistance.
      </span>
    );
  if (type === 'not-found')
    return (
      <span className="message">
        Sorry, the content you are looking for does not exist, either it was removed or you mistyped
        the link.Please contact{' '}
        <a href="mailto:help@beewo.com" className="support-mail">
          help@beewo.com
        </a>{' '}
        for assistance.
      </span>
    );
  if (type === 'server-error')
    return (
      <span className="message">
        We are working on fixing the problem.
        <br /> Please try again later or refresh the page.
      </span>
    );

  return null;
};

const SupportErrorPagesTitle = (type: SupportErrorPagesProps['type']) => {
  if (type === 'access-denied') return 'Access Denied!';
  if (type === 'not-found') return 'Page not Found!';
  if (type === 'server-error') return 'We have a little problem here!';

  return null;
};

const SupportErrorPages = ({ type }: SupportErrorPagesProps) => {
  const { userIsFailed, userIsDeactivatedWithActiveOrderlines } = usePermissionContext();
  return (
    <FlexBox UNSAFE_className="support-error-page" alignItems="center" justifyContent="center">
      <FlexBox
        UNSAFE_className="support-error-page-container"
        flexDirection="column"
        gap="41px"
        alignItems="center"
      >
        <SupportErrorPagesIcon type={type} />
        <FlexBox
          flexDirection="column"
          alignItems="center"
          gap="6px"
          UNSAFE_style={{ maxWidth: '585px' }}
        >
          <span className="title">{SupportErrorPagesTitle(type)}</span>
          <SupportErrorPagesMessage type={type} />
        </FlexBox>
        <a
          href={
            userIsFailed || userIsDeactivatedWithActiveOrderlines
              ? '/profile/billing'
              : '/dashboard'
          }
        >
          <Button kind="primary" weight="bold" size="l">
            Back to Beewo
          </Button>
        </a>
      </FlexBox>
    </FlexBox>
  );
};

export default SupportErrorPages;
