import React, { Fragment } from 'react';
import type { ReactNode } from 'react';
import './Breadcrumbs.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TRootState } from 'types';
import { UserStatus } from 'enums';

type BreadcrumbType = {
  path: string;
  name?: string;
};

export const Breadcrumbs = ({
  breadcrumbs,
  separator,
}: {
  breadcrumbs: BreadcrumbType[];
  separator?: string | ReactNode;
}) => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const isUserAactive =
    userData?.status === UserStatus.ON_HOLD || userData?.status === UserStatus.ACTIVE;
  return (
    <div className="Breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        if (!isUserAactive && index + 1 !== breadcrumbs.length) {
          return (
            <Fragment key={`${breadcrumb.name}-${breadcrumb.path}`}>
              <div
                className={classNames(
                  'Breadcrumbs__section',
                  index + 1 === breadcrumbs.length && 'Breadcrumbs__section--is-active',
                  !isUserAactive && 'disabled'
                )}
              >
                {breadcrumb.name?.replaceAll('-', ' ')}
              </div>
              {!(index + 1 === breadcrumbs.length) && (
                <div className="Breadcrumbs__separator">{separator || '>'}</div>
              )}
            </Fragment>
          );
        }

        if (index + 1 === breadcrumbs.length) {
          return (
            <Fragment key={`${breadcrumb.name}-${breadcrumb.path}`}>
              <div
                className={classNames(
                  'Breadcrumbs__section',
                  index + 1 === breadcrumbs.length && 'Breadcrumbs__section--is-active'
                )}
              >
                {breadcrumb.name?.replaceAll('-', ' ')}
              </div>
              {!(index + 1 === breadcrumbs.length) && (
                <div className="Breadcrumbs__separator">{separator || '>'}</div>
              )}
            </Fragment>
          );
        }

        return (
          <Fragment key={`${breadcrumb.name}-${breadcrumb.path}`}>
            <Link
              to={breadcrumb.path}
              className={classNames(
                'Breadcrumbs__section',
                index + 1 === breadcrumbs.length && 'Breadcrumbs__section--is-active'
              )}
            >
              {breadcrumb.name?.replaceAll('-', ' ')}
            </Link>
            {!(index + 1 === breadcrumbs.length) && (
              <div className="Breadcrumbs__separator">{separator || '>'}</div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
