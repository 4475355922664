import React, { FC } from 'react';
import { Text } from '@eltoro-ui/components';
import './DeleteLabel.scss';
import { usePermissionContext } from 'contexts/PermissionContext';

type deleteLable = {
  onDelete: () => void;
  deleteItemCounts: number;
  title: string;
  deletable: boolean;
};

export const DeleteLable: FC<deleteLable> = ({ onDelete, deleteItemCounts, title, deletable }) => {
  const { userIsDeactivatedWithActiveOrderlines } = usePermissionContext();
  return (
    <div className="TableSelectionWrapper__selection-header">
      <Text on="white" UNSAFE_className="TableSelectionWrapper__selected-row">
        {`${deleteItemCounts} ${title}${deleteItemCounts > 1 ? 's' : ''} selected`}
      </Text>
      {deletable && (
        <div className="TableSelectionWraper__actions">
          <button
            type="button"
            className={
              userIsDeactivatedWithActiveOrderlines
                ? 'Campaigns__select-action Campaigns__select-action_disabled'
                : 'Campaigns__select-action'
            }
            onClick={onDelete}
            disabled={userIsDeactivatedWithActiveOrderlines}
          >
            <Text UNSAFE_className="TableSelectionWrapper__selected-row-delete" on="tertiary-300">
              Delete
            </Text>
          </button>
        </div>
      )}
      <div className="bottomAges" />
    </div>
  );
};
