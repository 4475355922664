import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { TRootState } from 'types';
import { UserStatus } from 'enums';
import { useHistory, useLocation } from 'react-router-dom';
import { setUser } from 'Redux/actions';
import { logout, subscribeTopic } from 'Requests';

export type PropsWithChildren = {
  children: ReactNode;
};

type PermissionContextType = {
  userIsDeactivated: boolean;
  userIsFailed: boolean;
  userIsOnHold: boolean;
  hasActiveOrderline: boolean;
  userIsDeactivatedWithActiveOrderlines: boolean;
  userIsActive: boolean;
  isDeactivatedInfoVisible: boolean;
  isReactivateInfoVisible: boolean;
  showReactivationModal: boolean;
  closeReactivationModal: () => void;
};

const defaultValues: PermissionContextType = {
  userIsDeactivated: false,
  userIsFailed: false,
  userIsOnHold: false,
  hasActiveOrderline: false,
  userIsDeactivatedWithActiveOrderlines: false,
  userIsActive: false,
  isDeactivatedInfoVisible: false,
  isReactivateInfoVisible: false,
  showReactivationModal: false,
  closeReactivationModal: () => {},
};

const PermissionContext = createContext<PermissionContextType>(defaultValues);

export const usePermissionContext = () => useContext(PermissionContext);

const PermissionContextProvider = ({ children }: PropsWithChildren) => {
  const history = useHistory();
  const location = useLocation();

  const [showReactivationModal, setShowReactivationModal] = useState(false);

  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state?.userReducer);
  const userIsActive =
    userData?.status === UserStatus.ACTIVE || userData?.status === UserStatus.ON_HOLD;
  const userIsDeactivated = userData?.status === UserStatus.DEACTIVATED;
  const userIsFailed = userData?.status === UserStatus.PAYMENT_FAIL;
  const userIsSuspended = userData?.status === UserStatus.SUSPENDED;
  const userIsClosed = userData?.status === UserStatus.CLOSED;
  const userIsOnHold = userData?.status === UserStatus.ON_HOLD;
  const hasActiveOrderline = !!userData?.active_orderline_count;
  const isDeactivatedInfoVisible =
    !!userData?.current_subscription?.cancelled_at && userData?.status === UserStatus.ACTIVE;
  const isReactivateInfoVisible =
    !!userData?.current_subscription?.cancelled_at && userData?.status === UserStatus.DEACTIVATED;

  const accessableRoutes = [
    '/campaign-dashboard',
    '/campaigns',
    '/profile',
    '/profile/billing',
    '/profile/notifications',
    '/profile/terms-policies',
    '/profile/password-reset',
  ];

  const userIsDeactivatedWithActiveOrderlines =
    (userIsDeactivated || userIsFailed) && hasActiveOrderline;
  useEffect(() => {
    if (
      (userIsDeactivatedWithActiveOrderlines || userIsFailed) &&
      !accessableRoutes.some(path => path === location.pathname)
    ) {
      history.replace(location.pathname, { access_denied: true });
    } else if (!userIsActive && !userIsOnHold) {
      if (userIsDeactivated && !hasActiveOrderline && !localStorage.getItem('reactivation')) {
        const userFcmToken = localStorage.getItem('firebase_notification');
        logout(userFcmToken);
        if (userFcmToken) {
          subscribeTopic(userFcmToken, 0);
        }
        localStorage.clear();
        dispatch(setUser({}));
        history.push('/login');
      }
    }
  }, [userIsDeactivated, hasActiveOrderline, location.pathname]);

  const closeReactivationModal = () => {
    setShowReactivationModal(false);
  };

  return (
    <PermissionContext.Provider
      value={{
        userIsDeactivated,
        userIsFailed,
        userIsOnHold,
        hasActiveOrderline,
        userIsDeactivatedWithActiveOrderlines,
        userIsActive,
        isDeactivatedInfoVisible,
        isReactivateInfoVisible,
        showReactivationModal,
        closeReactivationModal,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
