import React, { forwardRef } from 'react';
import type { ReactNode, Ref } from 'react';

import classNames from 'classnames';
import { Text } from '@eltoro-ui/components';

import type { AnyObject } from 'types';

import './TableSelectionWrapper.scss';

type TableSelectionWrapperType<T> = {
  children: ReactNode;
  selectionType: string;
  selections: T[];
  actions: ReactNode;
  UNSAFE_CLASSNAME_WRAPPER?: string;
};

export const TableSelectionWrapper = forwardRef(
  <T extends AnyObject = AnyObject>(
    {
      children,
      selectionType,
      selections,
      actions,
      UNSAFE_CLASSNAME_WRAPPER,
    }: TableSelectionWrapperType<T>,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div className="TableSelectionWrapper">
        {selections.length > 0 ? (
          <div className="TableSelectionWrapper__selection-header">
            <Text on="white" UNSAFE_className="TableSelectionWrapper__selection_text">
              {`${selections.length} ${selectionType}${selections.length > 1 ? 's' : ''} selected`}
            </Text>
            <div className="TableSelectionWraper__actions">
              {Array.isArray(actions)
                ? actions.map((action, i) => <React.Fragment key={i}>{action}</React.Fragment>)
                : actions}
            </div>
          </div>
        ) : (
          <div className="TableSelectionWrapper__selection-placeholder" />
        )}
        <div
          className={classNames('TableSelectionWrapper__table-wrapper', UNSAFE_CLASSNAME_WRAPPER, {
            'TableSelectionWrapper__table-wrapper--selection': selections?.length > 0,
          })}
          ref={ref} // Attach the forwarded ref here
        >
          {children}
        </div>
      </div>
    );
  }
);
