import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Button } from '@eltoro-ui/components';
import type { TCurrentSubscription, TCommonUser } from 'types';

import { userDetail } from 'Requests';
import { userRole } from 'Helpers';
import { setUser } from 'Redux/actions';
import { UserStatus } from 'enums';
import { cancelSubscription } from 'Requests/Request_Methods/chargebeeMethods';
import DeactivateAccountModal from './components/DeactivateAccountModal';

import './DeactivateAccount.scss';

type DeactivateAccountProps = {
  user: TCommonUser;
};

const DeactivateAccount = ({ user }: DeactivateAccountProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const loaction = useLocation();

  const onDeactivateAccount = async () => {
    try {
      setLoading(true);
      await cancelSubscription({
        sub_id: user.current_subscription?.sub_id as TCurrentSubscription['sub_id'],
      });
      const token = localStorage.getItem('beewo_token') || undefined;
      userDetail(token).then(res => {
        const userData = {
          user_role: userRole(res.data?.is_super),
          ...res.data,
        };
        dispatch(setUser(userData));
        setLoading(false);
      });
      toast.success('Your account deactivation request was sent successfully.');
    } catch (e) {
      toast.error('Oops, your account deactivation request has failed.');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const onCancel = () => setOpen(false);

  const onClickCancel = () => setOpen(true);

  if (
    !(user?.status === UserStatus.ON_HOLD || user?.status === UserStatus.ACTIVE) ||
    user.current_subscription?.cancelled_at
  )
    return null;
  return (
    <div className="cancel-subscription-container">
      {open && (
        <DeactivateAccountModal
          user={user}
          onDeactivateAccount={onDeactivateAccount}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      {loaction.pathname === '/profile/billing' && (
        <Button UNSAFE_className="danger" kind="text" weight="bold" onClick={onClickCancel}>
          Deactivate My Account
        </Button>
      )}
    </div>
  );
};

export default DeactivateAccount;
