import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from '@eltoro-ui/components';
import { PersonalInfoForm } from 'Components';
import ConfirmPayment from 'Components/ConfirmPayment';
import type { TCommonSubscription, TRootState, UserInfoType } from 'types';

import SubscriptionPlanChoose from 'Components/SubscriptionPlanChoose';
import { getChargebeePackages } from 'Requests';

import { setUser } from 'Redux/actions';

import Congratulation from 'assets/Images/congratulation.svg';
import Leftgroup from 'assets/Images/leftgroup.svg';
import Rightgroup from 'assets/Images/rightgroup.svg';

import './OnboardingModal.scss';
import { GuideEnum, SubscriptionName, UserStatus } from 'enums';
import CardModal from '../SummaryCart/CardModal';

const emptyUser = {
  personalInfo: {
    brokerage: '',
    email: '',
    mls_id: '',
    mls_registered_name: '',
    phone_number: '',
    primary_zip_code: '',
    first_name: '',
    last_name: '',
    street: '',
    state: '',
    billing_zip_code: '',
    city: '',
  },
};

export const OnboardingModal = ({
  setModalOpen,
  reactivationFromLoginPage,
}: {
  setModalOpen: (value: boolean) => void;
  reactivationFromLoginPage?: boolean;
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const [selectedSubscription, setSelectedSubscription] = useState<TCommonSubscription | null>(
    null
  );
  const isReactivationFlow = useMemo(() => userData?.status === 'Deactivated', [userData]);
  const hasActiveOrderlines = useMemo(() => !!userData?.active_orderline_count, [userData]);

  const [currentUser, setCurrentUser] = useState<UserInfoType>(emptyUser);
  const [modalStep, setModalStep] = useState<number>(0);
  const [subscriptions, setSubscriptions] = useState<TCommonSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  useEffect(() => {
    setLoading(true);
    getChargebeePackages().then(res => {
      setSubscriptions(res.data?.subscriptions.reverse() || []);

      if (userData.current_subscription?.status) {
        const subscriptionKey = userData.current_subscription
          .item_price_id as keyof typeof SubscriptionName;

        const selectedSub =
          res.data?.subscriptions?.find(sub => sub.id === SubscriptionName[subscriptionKey]) ||
          null;
        setSelectedSubscription(selectedSub);
      }
      setLoading(false);
    });

    return () => {
      setSubscriptions([]);
    };
  }, [currentUser]);

  const history = useHistory();

  useEffect(() => {
    const currentModalStep = () => {
      if (userData.status === UserStatus.REGISTERED) setModalStep(1);
      if (userData.status === UserStatus.CONFIRMED) setModalStep(2);
      if (
        reactivationFromLoginPage ||
        (userData?.active_orderline_count && userData.status === UserStatus.DEACTIVATED) ||
        userData.status === UserStatus.PAYMENT_FAIL
      )
        setModalStep(2);
    };
    setTimeout(currentModalStep, 0);
  }, []);

  const populatePersonalInfo = (info: UserInfoType['personalInfo']) => {
    const tempData = {
      ...userData,
      ...info,
    };

    dispatch(setUser(tempData));
    setCurrentUser({
      ...currentUser,
      personalInfo: { ...info },
    });
  };

  const onContinue = () => {
    if (isReactivationFlow || userData.status === UserStatus.PAYMENT_FAIL) {
      setCardModalOpen(true);
      setModalStep(5);
    } else {
      setModalStep(3);
    }
  };

  const onPreviousStep = () => {
    setModalStep(step => step - 1);
  };

  const handleClick = () => {
    setModalOpen(false);
    sessionStorage.setItem(
      'guideBotContacts',
      JSON.stringify({
        isActive: true,
        type: GuideEnum.CONTACTS,
        msgType: '',
        count: 0,
      })
    );
    window.location.reload();
    history.push('/dashboard');
  };

  const closeCardModal = () => setCardModalOpen(false);
  return (
    <Modal style={{ maxWidth: 'calc(100vw - 10rem)' }}>
      {modalStep === 1 && (
        <PersonalInfoForm
          user={currentUser}
          onSubmit={value => {
            populatePersonalInfo(value);
            setModalStep(2);
          }}
        />
      )}
      {modalStep === 2 && (
        <SubscriptionPlanChoose
          loading={loading}
          onContinue={onContinue}
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
          isReactivationFlow={isReactivationFlow}
          setModalOpen={setModalOpen}
          hasActiveOrderlines={hasActiveOrderlines}
        />
      )}
      {modalStep === 3 && (
        <ConfirmPayment
          subscription={selectedSubscription}
          onPreviousStep={onPreviousStep}
          setModalStep={setModalStep}
        />
      )}
      {modalStep === 4 && (
        <div className="OnboardingModal-welcome">
          <img className="Leftgroup" src={Leftgroup} alt="Leftgroup" />
          <div className="OnboardingModal-welcomeHeader">
            <div className="beewo-header-congrats">
              <img src={Congratulation} alt="congrats" />
              <p>Congratulations!</p>
            </div>
            <div className="beewo-header-welcome">
              <p>Welcome to Beewo</p>
            </div>
            <div className="description">
              <p>
                You&apos;ve successfully created your profile. Click below <br />
                to start accessing the platform.
              </p>
            </div>
          </div>
          <div className="btn-container">
            <Button
              UNSAFE_style={{
                borderRadius: '10px',
                padding: '26px 20px',
                color: '#2B1F0A',
              }}
              kind="primary"
              size="l"
              weight="bold"
              width="30%"
              onClick={handleClick}
            >
              Get Started
            </Button>
          </div>
          <img className="Rightgroup" src={Rightgroup} alt="Rightgroup" />
        </div>
      )}
      {cardModalOpen && modalStep === 5 && (
        <CardModal
          isReactivationFlow
          selectedSubscription={selectedSubscription}
          closeCardModal={closeCardModal}
          setModalOpen={setModalOpen}
        />
      )}
    </Modal>
  );
};
