import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import { Text } from '@eltoro-ui/components';
import { Loading, Pagination, TableSelectionWrapper, EmptyStatus } from 'Components';

import { setUnReadNotification, setUnReadNotificationCount } from 'Redux/actions';
import {
  getUserNotifications,
  getUnReadNotificationsCounts,
  markAsReadAllNotifications,
  markAsReadSingleNotification,
} from 'Requests';
import { usePermissionContext } from 'contexts/PermissionContext';
import folder from 'assets/Images/folder.png';

import type { TNotifications, TUnReadTotalCounts } from 'types';

import './Notification.scss';

export const Notifications = () => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<TNotifications[]>([]);
  const [counts, setCounts] = useState<TUnReadTotalCounts[]>([]);
  const { userIsFailed, userIsDeactivatedWithActiveOrderlines, userIsActive } =
    usePermissionContext();
  const [pages, setPages] = useState<{
    total_notifications: number;
    total_pages: number;
    current_page: number;
  }>({
    total_notifications: 0,
    total_pages: 0,
    current_page: 1,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (counts.some(nots => !!nots.unreads)) {
      dispatch(setUnReadNotification(true));
      dispatch(setUnReadNotificationCount(counts.reduce((count, not) => count + not.unreads, 0)));
    } else {
      dispatch(setUnReadNotification(false));
      dispatch(setUnReadNotificationCount(0));
    }
  }, [counts]);

  useEffect(() => {
    setNotifications([]);
    _getUnReadNotificationsCounts();
  }, [pages.current_page, activeTab]);

  const _getUnReadNotificationsCounts = () => {
    getUnReadNotificationsCounts()
      .then(res => {
        if (res.data) setCounts(res.data);
      })
      .catch(err => {
        toast.error(err.detail);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isCancelledNotifications = false;
    const _getNotifications = (source?: string) => {
      getUserNotifications(pages.current_page, 20, source)
        .then(res => {
          if (!isCancelledNotifications) {
            if (res.data) {
              setNotifications(res.data.notifications);

              setPages({
                current_page: res.data.current_page || 1,
                total_notifications: res.data.total_notifications || 0,
                total_pages: res.data.total_pages || 0,
              });
            }

            setIsLoading(false);
          }
        })
        .catch(err => {
          if (!isCancelledNotifications) {
            toast.error(err.detail);
            setIsLoading(false);
          }
        });
    };
    if (userIsActive) {
      _getNotifications(activeTab);
    }

    return () => {
      isCancelledNotifications = true;
    };
  }, [activeTab]);

  const readSingleNotification = (id: string) => {
    setIsLoading(true);
    markAsReadSingleNotification([{ id, read: 1 }])
      .then(() => {
        _getUnReadNotificationsCounts();
      })
      .catch(err => {
        toast.error(err.detail);
        setIsLoading(false);
      });
  };

  const markAsAllRead = () => {
    setIsLoading(true);
    markAsReadAllNotifications(1)
      .then(() => {
        _getUnReadNotificationsCounts();
      })
      .catch(err => {
        toast.error(err.detail);
        setIsLoading(false);
      });
  };

  const tabOnChange = (src: string) => {
    setActiveTab(src);
  };
  const getBody = () => {
    let component = <></>;
    if (isLoading) {
      component = <Loading />;
    } else if (notifications?.length === 0 || ['accounts'].includes(activeTab)) {
      component = (
        <EmptyStatus
          icon={<img src={folder} alt="empty" />}
          heading="You do not have any notifications yet."
        />
      );
    } else {
      component = (
        <div className="body_container">
          {notifications.map(notification => {
            return (
              <ul
                key={notification.id}
                className={classNames('notification', {
                  'notification-unread': !notification.read,
                })}
                onClick={() =>
                  !notification.read ? readSingleNotification(notification.id) : undefined
                }
                role="presentation"
              >
                <li className="notification_content">
                  <span className="notification_content-title">{notification.title}</span>
                  <span className="notification_content-description">{notification.message}</span>
                </li>
                <li className="notification_created">
                  <span>{moment(notification.created_at).subtract(5).fromNow()}</span>
                </li>
              </ul>
            );
          })}
          {!!notifications?.length && (
            <div
              className="pagiate_container"
              style={{
                position: notifications.length > 4 ? 'relative' : 'absolute',
              }}
            >
              <Pagination
                title="notifications"
                loading={isLoading}
                currentPage={pages?.current_page}
                totalItems={pages?.total_notifications}
                totalPages={pages?.total_pages}
                onClickPrev={() => {
                  setIsLoading(true);
                  setPages({
                    ...pages,
                    current_page: pages.current_page - 1,
                  });
                }}
                onClickNext={() => {
                  setIsLoading(true);
                  setPages({
                    ...pages,
                    current_page: pages.current_page + 1,
                  });
                }}
              />
            </div>
          )}
        </div>
      );
    }
    return component;
  };

  const getCount = (src: string) => {
    const _count = counts.find(count => count?.source === src && count?.total)?.unreads;
    return _count || 0;
  };

  return (
    <div className="notification_container">
      <TableSelectionWrapper
        selectionType="campaign"
        UNSAFE_CLASSNAME_WRAPPER="notification_wrapper"
        selections={[]}
        actions={[]}
      >
        <div className="nested_container">
          <div className="header_container">
            <ul className="header_titles">
              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === '',
                })}
                role="presentation"
                onClick={() => tabOnChange('')}
              >
                <Text on="white" weight="bold">
                  All
                </Text>
                <span
                  className={classNames('count', {
                    black_bg_color: activeTab === '',
                  })}
                >
                  {counts.reduce((count, not) => count + not.unreads, 0)}
                </span>
              </li>

              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === 'campaign',
                })}
                role="presentation"
                onClick={() => tabOnChange('campaign')}
              >
                <Text on="white" weight="bold">
                  campaign
                </Text>
                <span
                  className={classNames('count', {
                    black_bg_color: activeTab === 'campaign',
                  })}
                >
                  {getCount('campaign')}
                </span>
              </li>

              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === 'contacts',
                })}
                role="presentation"
                onClick={() => tabOnChange('contacts')}
              >
                <Text on="white" weight="bold">
                  contacts
                </Text>
                <span
                  className={classNames('count', {
                    black_bg_color: activeTab === 'contacts',
                  })}
                >
                  {getCount('contacts')}
                </span>
              </li>
              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === 'accounts',
                })}
                role="presentation"
                onClick={() => tabOnChange('accounts')}
              >
                <Text on="white" weight="bold">
                  account
                </Text>
                <span
                  className={classNames('count', {
                    black_bg_color: activeTab === 'accounts',
                  })}
                >
                  {getCount('accounts')}
                </span>
              </li>
            </ul>
            <ul className="notification__containerHeader">
              <li
                className={classNames('mark_all_as_read', {
                  'have-notifications': !!notifications.length,
                })}
                onClick={notifications.length ? markAsAllRead : () => undefined}
                role="presentation"
              >
                <span>Mark all as read</span>
              </li>
            </ul>
          </div>

          {getBody()}
        </div>
      </TableSelectionWrapper>
    </div>
  );
};
