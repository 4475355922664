import type { MenuItem } from 'primereact/menuitem';
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import type { ButtonProps } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Button as PrimeButton } from 'primereact/button';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import type { SocialMediaPost } from 'types';

interface SocialMediaActionAccessorProps {
  post: SocialMediaPost;
  onViewDetailsAction: (post: SocialMediaPost) => MenuItem['command'];
}

const SocialMediaActionAccessor = ({
  post,
  onViewDetailsAction,
}: SocialMediaActionAccessorProps) => {
  const postActionRef = useRef<Menu>(null);
  const menuRootWrapperRef = useRef<HTMLDivElement>(null);

  const togglePostActionSelect: ButtonProps['onClick'] = event => {
    if (postActionRef && postActionRef.current) {
      postActionRef.current.toggle(event);
    }
  };

  const items: MenuItem[] = [
    {
      label: 'See Details',
      icon: <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 12 }} />,
      command: onViewDetailsAction(post),
    },
  ];

  return (
    <div ref={menuRootWrapperRef} className="post-actions-wrapper">
      <Menu
        className="post-actions-menu"
        ref={postActionRef}
        popup
        id="popup_menu_right"
        popupAlignment="right"
        model={items}
      />
      <PrimeButton
        className="post-action-btn"
        disabled={!post.permalink}
        icon={
          <FontAwesomeIcon
            icon={faEllipsisV}
            color={!post.permalink ? '#D7D7D7' : '#757575'}
            style={{ fontSize: 20 }}
          />
        }
        onClick={togglePostActionSelect}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </div>
  );
};

export default SocialMediaActionAccessor;
