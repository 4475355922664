import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import stop from 'assets/Images/Stop.svg';
import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { reactivateSubscription } from 'Requests/Request_Methods/chargebeeMethods';
import type { TRootState } from 'types';
import { userDetail } from 'Requests';
import { setUser } from 'Redux/actions';
import { SubscriptionName } from 'enums';

type ReactivateModalProps = {
  closeModal: () => void;
};
const ReactivateModal = ({ closeModal }: ReactivateModalProps) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const dispatch = useDispatch();

  const onDeactivateAccount = async () => {
    try {
      setLoading(true);
      if (userData.sub_name) {
        await reactivateSubscription({
          plan: SubscriptionName[userData.sub_name],
        });
        const token = localStorage.getItem('beewo_token') || undefined;
        userDetail(token).then(res => {
          const userData = {
            ...res.data,
          };
          dispatch(setUser(userData));
          setLoading(false);
        });
        toast.success('Your account deactivation request was canceled.');
      }
    } catch (e) {
      toast.error('Oops, your account deactivation request has failed.');
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  return (
    <Modal offClick={closeModal}>
      <div className="OnboardingModal__welcome ownership_position">
        <div className="OnboardingModal__welcomeHeader ownership_modal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon icon={faTimes} onClick={closeModal} color="#6d6d6d" />
          </div>
          <FlexBox alignItems="center" flexDirection="column" gap="20px">
            <img src={stop} alt="your listing" />
            <Text on="white" weight="bold">
              Cancel Deactivation Request?
            </Text>
            <p className="is_your_listing_or_not">
              You will be charged on next billing day and you can continue using Beewo’s benefits
              for the consecutive subscription periods.
            </p>
          </FlexBox>
          <FlexBox justifyContent="center" gap="32px" UNSAFE_className="is_your_listing_btns">
            <Button
              size="l"
              kind="default"
              weight="bold"
              onClick={closeModal}
              UNSAFE_className="cancel_ownership_btn"
            >
              No, Thanks
            </Button>
            <Button
              size="l"
              kind="primary"
              weight="bold"
              onClick={onDeactivateAccount}
              disabled={loading}
            >
              Cancel Request
            </Button>
          </FlexBox>
        </div>
      </div>
    </Modal>
  );
};

export default ReactivateModal;
