import React, { useEffect, useState } from 'react';

import { Button, Modal } from '@eltoro-ui/components';

import { Loading } from 'Components';

import CanvaDesignCard from 'Pages/CreativesLibrary/components/CanvaDesignsModal/components/CanvaDesignCard';

import { exportDesign, getDesigns } from 'canva-integration/services';

import { validateBanner } from 'Components/Uploader';

import type { Design } from 'canva-integration/type';

import './CanvaDesignsModal.scss';

interface CanvaDesignsModalProps {
  open: boolean;
  onSubmit: (base64s: any[]) => Promise<void>;
  onCancel: VoidFunction;
}

export default function CanvaDesignsModal({ open, onSubmit, onCancel }: CanvaDesignsModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [canvaDesigns, setCanvaDesigns] = useState<Array<Design>>([]);

  const [selectedDesign, setSelectedDesign] = useState<Design | null>(null);

  const getCanvaDesigns = async () => {
    try {
      setIsLoading(true);
      const { items } = await getDesigns();
      setCanvaDesigns(items.filter(design => design.thumbnail));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) getCanvaDesigns();
  }, [open]);

  const onSelectDesign = (design: Design) => () => {
    setSelectedDesign(selectedDesign => {
      if (selectedDesign) {
        if (selectedDesign.id === design.id) return null;
        return design;
      }
      return design;
    });
  };

  const toBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onUpload = async () => {
    try {
      setIsUploading(true);
      if (selectedDesign) {
        const { job } = await exportDesign({ designId: selectedDesign.id });
        if (job.urls) {
          const response = await fetch(job.urls[0]);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', { type: 'image/png' });

          const { file: validatedFile, isValid } = await validateBanner(file);

          if (isValid) {
            const base64 = await toBase64(blob);
            const [width, height] = validatedFile.file_specs?.split('x').map(Number);
            await onSubmit([{ handleDataURL: base64, width, height }]);
            await onCancel();
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsUploading(false);
    }
  };

  if (!open) return null;

  return (
    <Modal className="canva-designs-modal">
      <div className="canva-designs-modal-header">
        <span className="canva-designs-modal-header-title">Select a design from Canva</span>
      </div>
      <div className="canva-designs-modal-content">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="canva-designs-card-content">
            <div className="canva-designs-card-container">
              {canvaDesigns.map(design => (
                <CanvaDesignCard
                  key={design.id}
                  design={design}
                  selected={design.id === selectedDesign?.id}
                  onSelectDesign={onSelectDesign(design)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="choose-from-creatives-library-footer">
        <Button onClick={onCancel} weight="bold" size="l">
          Cancel
        </Button>
        <Button
          kind="primary"
          weight="bold"
          size="l"
          loading={isUploading}
          disabled={isUploading || isLoading || !selectedDesign}
          onClick={onUpload}
        >
          Upload
        </Button>
      </div>
    </Modal>
  );
}
