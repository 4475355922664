import React from 'react';
import { FlexBox, Text, Button } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

export const SummaryTotal: React.FC<{
  total: number;
  isEditDisabled: boolean;
  isNegative?: Boolean;
  discount?: number;
  handleOpen?: VoidFunction;
}> = ({ total, isNegative, discount, handleOpen, isEditDisabled }) => {
  const budgetAdjustmentFlag = useFlag('budget-adjustment');
  const { flagsReady } = useFlagsStatus();

  return (
    <FlexBox alignItems="center" flexDirection="column" justifyContent="center">
      <FlexBox alignItems="center" justifyContent="space-between" UNSAFE_style={{ width: '100%' }}>
        <Text size="m" on="white" weight="bold">
          <span style={{ color: '#2B1F0A', fontWeight: 700 }}>Total Spend</span>
        </Text>

        <Text size="m" on="white" weight="bold" tag="div">
          <span style={{ fontWeight: 700 }}>
            ${isNegative ? ' -' : ''} {total.toLocaleString()}
          </span>
        </Text>
      </FlexBox>
      {budgetAdjustmentFlag && flagsReady && (
        <FlexBox alignItems="center" justifyContent="flex-end" UNSAFE_style={{ width: '100%' }}>
          <Button
            type="button"
            kind="text"
            size="m"
            weight="bold"
            UNSAFE_className="cost_edit"
            disabled={isEditDisabled}
            onClick={handleOpen}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
        </FlexBox>
      )}
    </FlexBox>
  );
};
