/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faLockAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Form } from '@eltoro-ui/components';
import { useForm, useValidatedState } from '@eltoro-ui/hooks';

import { LabelInputField } from 'Components/LabelInputField';
import { ErrorMsg, LoginFooter, LoginHeader, Tooltip } from 'Components';

import { userRole } from 'Helpers';

import { setUser, setUserComplete } from 'Redux/actions';

import { userDetail, userLogin } from 'Requests';

import logo from 'Images/Icon_color@2x.png';
import Warning from 'Images/warning.png';

import './Login.scss';
import 'Components/LabelInputField/LabelInputField.scss';
import { UserStatus, UserStatusLoginDenied } from 'enums';
import InfoBarInLoginPage from './InfoBarInLoginPage';
import ReturnModal from 'Components/ReturnModal';

type LocationFromState = {
  from: string;
};

export const Login = () => {
  const history = useHistory();
  const location = useLocation<LocationFromState>();
  const focusInput = useRef(null);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [reactivateInfoOpen, setReactivateInfoOpen] = useState(false);
  const { isValidForm, touched } = useForm({
    email,
    password,
  });
  const [resp, setResp] = useState(Boolean);
  const [wrongPassword, setWrongPassword] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const required = ['email', 'password'];
  const { validForm, missingFields } = isValidForm([...required, isValidEmail]);
  const [valid, setValid] = useState(false);
  const [showSuspendInfo, setShowSuspendInfo] = useState<UserStatusLoginDenied | null>(null);
  const [reactivationFromLoginPage, setReactivationFromLoginPage] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, []);

  const getUserDetails = (token: string) => {
    userDetail(token)
      .then(async response => {
        if (response?.data?.email) {
          setUserToken(token, response?.data?.email);
          const userData = {
            user_role: userRole(response?.data?.is_super),

            ...response.data,
          };
          dispatch(setUser(userData));

          if (
            [UserStatus.REGISTERED, UserStatus.CONFIRMED, UserStatus.COMPLETE].includes(
              userData.status
            )
          ) {
            dispatch(setUserComplete(false));
            history.replace('/dashboard');
          } else if (
            userData.status === UserStatus.PAYMENT_FAIL ||
            (userData.status === UserStatus.DEACTIVATED && userData.active_orderline_count)
          )
            history.replace('/profile/billing');
          else history.replace(location.state?.from || '/dashboard');
        }
      })
      .catch(e => {
        toast.error(e);
      });
  };

  const setUserToken = (token: string, email: string) => {
    localStorage.setItem('beewo_token', token);
    localStorage.setItem('userMail', email);
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    setLoading(true);
    setResp(false);
    // e.preventDefault()
    const body = { username: email, password };
    userLogin(body)
      .then((res: any) => {
        if (res.data.token) {
          setAccessToken(res.data.token);
          if (!res.data.active_orderlines && res.data.user_status === UserStatus.DEACTIVATED) {
            setReactivateInfoOpen(true);
            setReactivationFromLoginPage(true);
            setLoading(false);
            localStorage.setItem('reactivation', 'true');
            return { loggedIn: false };
          }
          getUserDetails(res.data.token);

          sessionStorage.removeItem('guideBotContacts');
          sessionStorage.removeItem('guideBotRecommendation');
        } else {
          setResp(false);
          setValid(false);
          setIsSubmit(true);
          setLoading(false);
          return { loggedIn: false };
        }
        return { loggedIn: false };
      })
      .catch(err => {
        if (err.detail === 'You entered wrong email address or password') {
          setIsError(true);
          setLoading(false);
        }
        if (err.data.detail.user_status) {
          setShowSuspendInfo(err.data.detail.user_status);
          setEmail('');
          setPassword('');
        }
        setResp(false);
        setValid(false);
        setIsSubmit(true);
        setLoading(false);
        return { loggedIn: false };
      });
  };

  const ErrorToolbar = () => {
    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          padding="9px 5px"
          text="Wrong username or password"
          topAbsolute="-91px"
          rightAbsolute="-81px"
        />
      </div>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(false);
    setPassword(e.target.value);
    setIsSubmit(false);

    if (e.target.value) {
      setWrongPassword(false);
    } else {
      setWrongPassword(true);
    }
  };
  const ForgotPassword = () => {
    history.push('/email-verify');
  };

  if (location.pathname === '/login/sa') {
    history.push('/login/super-admin');
  }
  const [superAdminRoute, setsuperAdminRoute] = useState(
    location.pathname === '/login/super-admin' && true
  );

  return (
    <div className="loginContainer">
      <LoginHeader />
      <div className="Login">
        <div className="Login_all_content">
          <div className="Login__logo">
            <img src={logo} alt="beewo-logo" className="public-beewo-logo" />
          </div>
          {showSuspendInfo && <InfoBarInLoginPage status={showSuspendInfo} />}
          {reactivateInfoOpen && reactivationFromLoginPage && (
            <ReturnModal
              token={accessToken}
              setUserToken={setUserToken}
              closeModal={() => setReactivateInfoOpen(false)}
              reactivationFromLoginPage={reactivationFromLoginPage}
            />
          )}
          <h3 className="login_heading">Login</h3>
          <div className="Login__form">
            <Form
              onSubmit={onSubmit}
              required={required}
              valid={validForm}
              missingFields={missingFields}
              touched={touched}
            >
              <LabelInputField
                title="Username"
                placeholder="Enter your username"
                isError={isError}
                type="Email"
                iconLeft={<FontAwesomeIcon icon={faUser} style={{ color: '#b3b0b0' }} />}
                value={email}
                iconRight={
                  isError ? (
                    <img
                      className="start_icon"
                      style={{ marginTop: '7px', width: '24px' }}
                      src={Warning}
                    />
                  ) : null
                }
                onChange={e => {
                  setEmail(e.target.value);
                  setIsSubmit(false);
                  setLoading(false);
                }}
              />
              {isValidEmail ? (
                ''
              ) : (
                <ErrorMsg
                  data_testid="error-email-input"
                  title="Invalid email"
                  icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
              )}
              {isError ? <ErrorToolbar /> : ''}
              {/*<br />*/}
              <div style={{ position: 'relative', marginTop: '20px' }}>
                <LabelInputField
                  title="Password"
                  placeholder="Enter your password"
                  id="pass"
                  value={password}
                  type={showPass === false ? 'password' : 'text'}
                  iconLeft={<FontAwesomeIcon icon={faLockAlt} style={{ color: '#b3b0b0' }} />}
                  isError={isError}
                  onChange={e => {
                    handleChange(e);
                  }}
                  EyePassIcon={
                    showPass ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          color: '#b3b0b0',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (focusInput.current) {
                            // @ts-ignore
                            focusInput.current.focus();
                          }
                          setshowPass(false);
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        style={{
                          color: '#b3b0b0',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          event.preventDefault();
                          document.getElementById('pass')?.focus();
                          if (focusInput.current) {
                            //  @ts-ignore
                            focusInput.current.focus();
                          }
                          setshowPass(true);
                        }}
                      />
                    )
                  }
                />
              </div>

              <div className="Login__options">
                <button
                  style={{ margin: '8px 0' }}
                  onClick={ForgotPassword}
                  className="forgotPassword"
                  type="button"
                >
                  Forgot Password?
                </button>
              </div>
              <Button
                UNSAFE_style={{
                  justifyContent: 'center',
                }}
                type="submit"
                kind="primary"
                size="l"
                width="100%"
                weight="bold"
                fonts="15px"
                name="sign_in"
                UNSAFE_className="login_submit"
                disabled={wrongPassword || !isValidEmail || !email || loading}
                loading={loading}
              >
                Sign in
              </Button>
              {!superAdminRoute && (
                <div className="loginBottom">
                  Don&apos;t have an account?
                  <button
                    onClick={() => {
                      history.push('/signup');
                    }}
                    className="Login__signupAcc"
                    type="button"
                  >
                    Sign up
                  </button>
                </div>
              )}
              <div className="loginBottom">
                <p>Having issues with your account? </p>
                <p>
                  Contact{' '}
                  <Link
                    to="/login"
                    className="loginBottomHelp"
                    style={{ paddingRight: '4px', color: '#FFAB03' }}
                  >
                    help@beewo.com
                  </Link>
                  for support
                </p>
              </div>
              {/* </form> */}
            </Form>
          </div>
        </div>
      </div>

      <LoginFooter />
    </div>
  );

  function newFunction(): any[] {
    return useState<boolean>(false);
  }
};
