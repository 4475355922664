import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';

interface Props {
  currentProspects: number;
  minProspectsCount: number;
}

const ProspectsHeader = ({ currentProspects, minProspectsCount }: Props) => {
  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      padding="1.5rem"
      UNSAFE_style={{
        width: '100%',
        background: '#FEFEFE',
        borderRadius: '12px 12px 0 0',
        border: '1px solid #d1d1d1',
        borderBottom: '0',
      }}
    >
      <FlexBox justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 16, marginBottom: '5px' }}
        >
          Audience
        </Text>
        <Text on="white" textAlign="left" UNSAFE_style={{ color: '#757575', fontSize: 14 }}>
          Based on your chosen criteria total prospect count cannot be less than {minProspectsCount}
          .
        </Text>
      </FlexBox>
      <Text
        on="white"
        weight="bold"
        textAlign="left"
        UNSAFE_style={{ color: '#2B1F0A', fontSize: 16, marginBottom: '5px' }}
      >
        {currentProspects} prospects
      </Text>
    </FlexBox>
  );
};

export default ProspectsHeader;
