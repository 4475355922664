import { Sidebar, SidebarProps } from 'primereact/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getSocialMediaPostById } from 'Requests/Request_Methods/socialMediaMethods';
import Tooltip from 'rc-tooltip';
import { SocialMediaPostById, TRootState } from 'types';
import { FlexBox } from '@eltoro-ui/components';
import './SocialMediaPostDetails.scss';
import { useSelector } from 'react-redux';
import PostContent from './PostContent';
import SocialMediaDetailSkeleton from './SocialMediaDetailSkeleton';

interface SocialMediaPostDetailsProps extends Pick<SidebarProps, 'onHide' | 'visible'> {
  id?: number;
  visible: boolean;
  getSocialMediaIcon: (socialType: number, pageImageUrl: string) => any;
  getSocialMediaChannel: (socialType: number) => any;
}
const SocialMediaPostDetails = ({
  id,
  visible,
  onHide,
  getSocialMediaIcon,
  getSocialMediaChannel,
}: SocialMediaPostDetailsProps) => {
  const [postLoading, setPostLoading] = useState(false);
  const [post, setPost] = useState<SocialMediaPostById | null>(null);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const onHideDrawer = () => {
    onHide();
    setPost(null);
  };
  const getPost = async (id: number) => {
    setPostLoading(true);
    try {
      if (userData.ms_api_key && userData.ms_secret_key && userData.secret) {
        const { data } = await getSocialMediaPostById(
          id,
          userData?.ms_api_key,
          userData?.ms_secret_key,
          userData?.secret
        );
        setPost(data);
      } else {
        setPostLoading(false);
      }
    } catch (e: any) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setPostLoading(false);
    }
  };

  useEffect(() => {
    if (id && visible) getPost(id);
  }, [id, visible]);

  const header = (
    <FlexBox gap="20px">
      <span className="post-details-drawer-header-text">Post Details</span>
      <a
        href={post?.permalink}
        target="_blank"
        rel="noreferrer"
        aria-label="Open post link in a new tab"
        className="go-to-post"
      >
        <Tooltip
          placement="top"
          trigger="hover"
          showArrow
          overlay="Go to post"
          getTooltipContainer={() => document.body}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} width={24} height={24} />
        </Tooltip>
      </a>
    </FlexBox>
  );
  return (
    <>
      <Sidebar
        className="post-details-drawer"
        position="right"
        header={header}
        visible={visible}
        onHide={onHideDrawer}
        closeIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />}
        appendTo={document.getElementById('root')}
      >
        {postLoading ? (
          <SocialMediaDetailSkeleton />
        ) : post ? (
          <PostContent
            post={post}
            getSocialMediaIcon={getSocialMediaIcon}
            getSocialMediaChannel={getSocialMediaChannel}
          />
        ) : null}
      </Sidebar>
    </>
  );
};
export default SocialMediaPostDetails;
