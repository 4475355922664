import React, { ChangeEvent, useState, useMemo } from 'react';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';
import { FlexBox, Text } from '@eltoro-ui/components';

interface Props {
  audienceName: string;
  maxProsp: number;
  listLength: number;
  isIncluded: boolean;
  defaultProspPercent: number;
  audienceId?: string | number | null;
  handleUpdateExclusion: (state: boolean, audienceId?: number) => void;
  handleProspectCountUpdate: (updatedCount: number, audienceId: number) => void;
}

const AudienceListItem = ({
  audienceName,
  listLength,
  maxProsp,
  isIncluded,
  audienceId,
  defaultProspPercent,
  handleUpdateExclusion,
  handleProspectCountUpdate,
}: Props) => {
  const [prospectsPercent, setProspectsPercent] = useState<number>(defaultProspPercent);
  const maxProspects = maxProsp;
  const minProspects = listLength > 1 ? 1 : 20;

  const prospects = useMemo(() => {
    return Math.round(+((prospectsPercent * maxProspects) / 100));
  }, [prospectsPercent]);

  const hasError = useMemo(
    () => prospects < minProspects || prospects > maxProspects,
    [prospects, maxProspects]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProspectsPercent((+e.target.value * 100) / maxProspects);
    const prosp = +e.target.value;
    handleProspectCountUpdate(prosp, audienceId as number);
  };

  const onSliderChange = (e: any) => {
    setProspectsPercent(+e.value);
    const prosp = Math.round(+((+e.value * maxProspects) / 100));
    handleProspectCountUpdate(prosp, audienceId as number);
  };

  const handleCheck = (e: any) => {
    handleUpdateExclusion(e.checked, audienceId as number);
  };

  const isDisabled = !isIncluded;

  return (
    <FlexBox
      UNSAFE_className={`cost_item_wrapper aud_item_wrapper ${isDisabled ? 'disabled_layer' : ''}`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="1.5rem"
      UNSAFE_style={{ background: '#FEFEFE', position: 'relative' }}
    >
      <FlexBox
        justifyContent="flex-start"
        alignItems="flex-start"
        padding="0 0 0.5rem"
        UNSAFE_style={{ width: '100%', borderBottom: '1px solid #E7E7E7' }}
      >
        {listLength > 1 && (
          <Checkbox onChange={handleCheck} checked={isIncluded} className="audience_checkbox" />
        )}
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{
            color: '#2B1F0A',
            fontSize: 16,
            textTransform: 'capitalize',
            marginLeft: '8px',
          }}
        >
          {audienceName}
        </Text>
      </FlexBox>
      <FlexBox
        UNSAFE_className="actions-wrapper"
        justifyContent="space-between"
        alignItems="center"
        padding="1.5rem 0"
      >
        <FlexBox
          UNSAFE_className="manual_input_wrapper"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Text
            on="white"
            textAlign="left"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 14, marginBottom: '8px' }}
          >
            Prospect count
          </Text>
          <input
            type="number"
            disabled={isDisabled}
            value={`${prospects}`}
            onChange={handleChange}
            min={minProspects}
            max={maxProspects}
            className={`manual_input ${hasError ? 'error_input' : ''}`}
          />
          {hasError && (
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{ color: '#FF2D38', fontSize: 14, marginTop: '8px' }}
            >
              {prospects < minProspects ? `Min is ${minProspects}` : `Max is ${maxProspects}`}
            </Text>
          )}
        </FlexBox>
        <FlexBox
          UNSAFE_className={`slider_input_wrapper ${
            minProspects === maxProspects ? 'disabled_slider' : ''
          }`}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Slider
            className="cost_slider"
            disabled={minProspects === maxProspects}
            value={prospectsPercent}
            min={(minProspects * 100) / maxProspects}
            max={100}
            step={1}
            onChange={onSliderChange}
          />
          <FlexBox
            UNSAFE_className="slider_input_wrapper"
            justifyContent="space-between"
            alignItems="center"
            UNSAFE_style={{ width: '100%', paddingTop: '0.5rem' }}
          >
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{
                color: isDisabled ? '#757575' : '#2B1F0A',
                fontSize: 14,
                marginBottom: '8px',
              }}
            >
              {minProspects}
            </Text>
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{
                color: isDisabled ? '#757575' : '#2B1F0A',
                fontSize: 14,
                marginBottom: '8px',
              }}
            >
              {maxProspects}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default AudienceListItem;
