import React from 'react';
import { FlexBox } from '@eltoro-ui/components';
import AudienceListItem from './AudienceListItem';

interface Props {
  listData?: any;
  handleUpdateExclusion: (state: boolean, audienceId?: number) => void;
  handleProspectCountUpdate: (updatedCount: number, audienceId: number) => void;
}

const AudiencesList = ({ listData, handleUpdateExclusion, handleProspectCountUpdate }: Props) => {
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="8px"
      UNSAFE_style={{ width: '100%' }}
    >
      {listData?.map(
        (audience: {
          id?: string | number | null;
          name: string;
          prospCount: number;
          length: number;
          isActive: boolean;
          maxProsp: number;
        }) => {
          const defaultProspPercent = +((audience.prospCount * 100) / audience.maxProsp).toFixed(1);

          return (
            <AudienceListItem
              defaultProspPercent={defaultProspPercent}
              key={audience.id}
              audienceName={audience.name}
              listLength={listData?.length}
              isIncluded={audience.isActive}
              audienceId={audience.id}
              handleUpdateExclusion={handleUpdateExclusion}
              handleProspectCountUpdate={handleProspectCountUpdate}
              maxProsp={audience.maxProsp}
            />
          );
        }
      )}
    </FlexBox>
  );
};

export default AudiencesList;
