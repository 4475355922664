import type { ResponseType } from 'types';
import axios from '../axios-default';

export const getSocialMediaPosts = async (
  page: number = 1,
  pageSize: number = 10,
  apiKey: string,
  secretKey: string,
  clientSecret: string,
  body: {}
): Promise<ResponseType<any>> =>
  axios.get(`${process.env.REACT_APP_MESSAGESPRING_API}?page=${page}&limit=${pageSize}`, {
    headers: {
      'x-client-secret': clientSecret,
      'x-secret-key': secretKey,
      'x-source': '1',
      Authorization: `Bearer ${apiKey}`,
    },
    params: body,
  });

export const getSocialMediaPostById = async (
  id: number,
  apiKey: string,
  secretKey: string,
  clientSecret: string
): Promise<ResponseType<any>> =>
  axios.get(`${process.env.REACT_APP_MESSAGESPRING_API}/${id}`, {
    headers: {
      'x-client-secret': clientSecret,
      'x-secret-key': secretKey,
      'x-source': '1',
      Authorization: `Bearer ${apiKey}`,
    },
  });
