import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import classNames from 'classnames';
import { Button } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'Components';
import { TCommonSubscription, TCommonUser, TCurrentSubscription, TRootState } from 'types';
import { UserStatus } from 'enums';

import KeepCurrentSubscription from './components/KeepCurrentSubscription';
import UpdatePlanModal from './components/UpdatePlanModal';

import './SubscriptionCard.scss';

export type SubscriptionCardProps = {
  data: TCommonSubscription[];
  onSubscribe: (body: { subscription_id: TCommonSubscription['id'] }) => () => void;
  onUpdateSubscription: (body: {
    id: TCurrentSubscription['sub_id'];
    plan_id: TCommonSubscription['id'];
  }) => () => void;
  onCancelUpcoming: ({ sub_id }: Pick<TCurrentSubscription, 'sub_id'>) => () => void;
  loading: boolean;
  userData: TCommonUser;
};

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  data,
  userData,
  onSubscribe,
  onUpdateSubscription,
  onCancelUpcoming,
  loading,
}) => {
  const { spinner } = useSelector((state: TRootState) => state.SpinnerReducer);

  const [updatePlanSubscription, setUpdatePlanSubscription] = useState<TCommonSubscription | null>(
    null
  );
  const [keepCurrentModal, setKeepCurrentModal] = useState<boolean>(false);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const conditionalText = (name: TCommonSubscription['name']) => {
    if (name.includes('basic')) return 'Activate Basic';
    if (name.includes('premium')) return 'Activate Premium';
    return 'Buy';
  };

  const planChangeDisabled =
    userData.status !== UserStatus.ACTIVE || userData.current_subscription?.cancelled_at;

  const onCancelUpdate = () => setUpdatePlanSubscription(null);

  const onUpgradePlan = () =>
    setUpdatePlanSubscription(
      data.find(subscription => subscription.name === 'beewo-subscription-premium') || null
    );

  const onDowngradePlan = () =>
    setUpdatePlanSubscription(
      data.find(subscription => subscription.name === 'beewo-subscription-basic') || null
    );

  const onStayOnCurrent = () => setKeepCurrentModal(true);
  const onCancelKeepCurrentPlan = () => setKeepCurrentModal(false);

  return (
    <div
      className={classNames(
        'subscription_card_container',
        userData?.current_subscription?.next_billing_at &&
          userData?.current_subscription?.scheduled_changes &&
          'has-upcoming'
      )}
    >
      {spinner && <Spinner />}
      {keepCurrentModal && (
        <KeepCurrentSubscription
          current={userData?.current_subscription}
          onKeepPlan={onCancelUpcoming}
          loading={loading}
          onCancel={onCancelKeepCurrentPlan}
        />
      )}
      {updatePlanSubscription && (
        <UpdatePlanModal
          user={userData}
          subscription={updatePlanSubscription as TCommonSubscription}
          onCancel={onCancelUpdate}
          onSubscribe={onSubscribe}
          onUpdateSubscription={onUpdateSubscription}
          loading={loading}
        />
      )}
      {userData?.current_subscription?.next_billing_at &&
        userData?.current_subscription?.scheduled_changes && (
          <div className="current_subscription-upcoming">
            <div className="current_subscription-upcoming-info">
              <span className="icon">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color="rgba(255, 182, 54, 1)"
                  style={{ fontSize: 31 }}
                />
              </span>
              <div className="current_subscription-upcoming-info-details">
                <div className="current_subscription-upcoming-info-details-title">
                  {userData?.sub_name === 'basic'
                    ? 'Your basic plan will be upgraded to premium tier on'
                    : 'Your premium plan will be downgraded to basic tier on'}{' '}
                  {moment(
                    userData?.current_subscription?.status === 'active'
                      ? userData?.current_subscription?.next_billing_at
                      : userData?.current_subscription?.trial_end
                  ).format('Do MMMM, YYYY')}
                  .
                </div>
                <div className="current_subscription-upcoming-info-details-content">
                  {userData?.sub_name === 'basic'
                    ? 'You will gain access to premium features at the end of the trial period'
                    : 'You will no longer have access to Premium features'}
                </div>
              </div>
            </div>
            <div className="current_subscription-upcoming-action">
              <Button
                kind="text"
                size="l"
                weight="bold"
                UNSAFE_className="stay-on-current"
                onClick={onStayOnCurrent}
              >
                <>
                  Stay on <span style={{ textTransform: 'capitalize' }}>{userData?.sub_name}</span>
                </>
              </Button>
            </div>
          </div>
        )}
      <div className="current_subscription_container">
        <div className="current_subscription-details">
          <div className="current_subscription-details-info">
            <span className="current_subscription-details-info-name">
              {userData?.sub_name} plan
              {userData?.current_subscription?.next_billing_at &&
                userData?.current_subscription?.scheduled_changes && (
                  <div className="current_subscription-details-active">Active</div>
                )}
            </span>
            <span className="current_subscription-details-info-cost">
              {/* {userData?.current_subscription */}
              {/*  ? formatter.format(userData?.current_subscription.amount / 100) */}
              {/*  : 0}{' '} */}
              {/* per month */}
              Free for Beta
            </span>
          </div>
          {/* {userData?.current_subscription?.next_billing_at && */}
          {/*  !planChangeDisabled && */}
          {/*  !userData?.current_subscription?.scheduled_changes && ( */}
          {/*    <> */}
          {/*      <div className="divider" /> */}
          {/*      <div className="current_subscription-details-next_payment"> */}
          {/*        <span className="current_subscription-details-next_payment-title"> */}
          {/*          Next Payment: */}
          {/*        </span> */}
          {/*        <span className="current_subscription-details-next_payment-date"> */}
          {/*          {moment(userData?.current_subscription.next_billing_at).format('LL')} */}
          {/*        </span> */}
          {/*      </div> */}
          {/*    </> */}
          {/*  )} */}
        </div>
        {!userData?.current_subscription?.scheduled_changes ? (
          <>
            {userData?.sub_name === 'basic' ? (
              <Button
                kind="primary"
                size="l"
                weight="bold"
                onClick={onUpgradePlan}
                disabled={planChangeDisabled}
              >
                Upgrade Plan
              </Button>
            ) : (
              <Button
                kind="text"
                size="l"
                weight="bold"
                UNSAFE_className="refresh-contacts"
                onClick={onDowngradePlan}
                disabled={planChangeDisabled}
              >
                Downgrade Plan
              </Button>
            )}
          </>
        ) : null}
      </div>
      {/* )} */}
    </div>
  );
};
