import { usePermissionContext } from 'contexts/PermissionContext';
import { Button } from '@eltoro-ui/components';
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import type { TRootState } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnboardingModal } from 'Components';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import DeactivatedAccountInfoBanner from './DeactivatedAccountInfoBanner';
import ReactivateModal from './ReactivateModal';

const DeactivateInfo = () => {
  const { isDeactivatedInfoVisible, isReactivateInfoVisible, userIsFailed } =
    usePermissionContext();
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const [openCancelModal, setCancelModal] = useState(false);
  const [openReactivationModal, setOpenReactivationModal] = useState<boolean>(false);
  const deactivationDate = moment(
    userData?.current_subscription?.current_term_end ??
      userData?.current_subscription?.next_billing_at
  ).format('MMMM D, YYYY');
  const reactivationDate = moment(
    userData?.current_subscription?.current_term_end ??
      userData?.current_subscription?.next_billing_at
  )
    .add(90, 'days')
    .format('MMMM D, YYYY');

  return (
    <>
      {isDeactivatedInfoVisible ? (
        <DeactivatedAccountInfoBanner
          heading={`Your account will be deactivated on ${deactivationDate}.`}
          description={`You can use any Beewo feature until the end of your subscription period which is ${deactivationDate}. If you have any active campaigns, they will run until their end date. You will be able to monitor any active campaigns or to reactivate your account by ${reactivationDate}`}
          button={
            <Button
              weight="bold"
              size="l"
              UNSAFE_className="cancel-request-button"
              onClick={() => setCancelModal(true)}
            >
              Cancel Request
            </Button>
          }
        />
      ) : isReactivateInfoVisible ? (
        <DeactivatedAccountInfoBanner
          heading="Your account is deactivated."
          description={`Your active campaigns will run until their end date. You will be able to monitor any active campaigns or to reactivate your account by ${reactivationDate}.`}
          button={
            <Button
              weight="bold"
              size="l"
              kind="primary"
              onClick={() => setOpenReactivationModal(true)}
            >
              Reactivate
            </Button>
          }
        />
      ) : userIsFailed ? (
        <DeactivatedAccountInfoBanner
          heading="We have encountered payment issues with your account."
          description="Please update your billing details to continue enjoying Beewo services"
          button={
            <Button
              weight="bold"
              size="l"
              kind="primary"
              iconLeft={<FontAwesomeIcon icon={faSync} />}
              onClick={() => setOpenReactivationModal(true)}
            >
              Retry Payment
            </Button>
          }
        />
      ) : null}
      {openCancelModal && <ReactivateModal closeModal={() => setCancelModal(false)} />}
      {openReactivationModal && <OnboardingModal setModalOpen={setOpenReactivationModal} />}
    </>
  );
};

export default DeactivateInfo;
