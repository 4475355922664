/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Button, FlexBox, Modal, Spacer, Text } from '@eltoro-ui/components';
import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom';
import { SummaryTotal } from 'Components/SummaryCart';
import { CreditModal } from 'Components/CreditModal';
import AddCard from 'Pages/MyProfile/components/BillingSection/components/AddCard/AddCard';
import SummaryEditModal from './SummaryEditModal';
import Tooltip from 'rc-tooltip';
import moment from 'moment';

import plusIcon from 'assets/Images/addcard.png';
import cross from 'assets/Images/cross.png';
import banner from 'assets/Images/Group870623.png';
import succesTick from 'assets/Images/Group87260.png';
import Lefticon from 'assets/Images/honeycombleft.png';
import Righticon from 'assets/Images/honeycombright.png';
import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import video from 'assets/Images/Subtract22.png';
import addcardbill from 'assets/Images/addcardbill.png';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { DEFAULT_MIN_FLIGHT_DATES } from 'Utils/constants';
import { brand } from 'Utils/helpers';
import { getPaymentCards, postCampaignBudget } from 'Requests';

import type { TRootState } from 'types';
import './SummaryCart.scss';

type SummaryCartProps = {
  setCampaignCostAccData?: any;
  campaignCostAccData?: any;
  isCheckout?: boolean;
  days: number;
};

export const SummaryCart: FC<SummaryCartProps> = ({
  setCampaignCostAccData,
  campaignCostAccData,
  days,
  isCheckout = false,
}) => {
  const budgetAdjustmentFlag = useFlag('budget-adjustment');
  const { flagsReady } = useFlagsStatus();
  const {
    startDate,
    endDate,
    bannerCPM,
    videoCPM,
    bannerFreq,
    videoFreq,
    campaign,
    minFlightDuration,
    changedAudiences,
    excludedAudiences,
    isConfirmed,
  } = campaignCostAccData || {};

  const { userData } = useSelector<TRootState, TRootState['userReducer']>(
    state => state.userReducer
  );

  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isCreditBuy, setIsCreditBuy] = useState(false);
  const [checkoutLoaded, isCheckoutLoaded] = useState(false);
  const [isSelectCard, setIsSelectCard] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seletedCardId, setSeletedCardId] = useState('');
  const [card, setCard] = useState<any>([]);
  const formattedDateRange = `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format(
    'MM/DD/YYYY'
  )}`;
  const durationInDays = moment(endDate).add({ days: 1 }).diff(moment(startDate), 'days');

  const cartProspectCount = useMemo(() => {
    let count = campaign?.prospects_counts;

    if (changedAudiences?.length) {
      const pastAndFutureAudineces = [...campaign.audience, ...changedAudiences].filter(
        item => !excludedAudiences.includes(item.id)
      );
      const seenIds = new Set();
      const uniqueAudiencesArr = [];

      for (let i = pastAndFutureAudineces.length - 1; i >= 0; i--) {
        const item = pastAndFutureAudineces[i];
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id);
          uniqueAudiencesArr.unshift(item); // Add to the beginning to preserve order
        }
      }

      count = uniqueAudiencesArr.reduce(
        (accumulator: any, current: { prospects_counts: any }) =>
          accumulator + current.prospects_counts,
        0
      );
    }

    return count;
  }, [campaign?.prospects_counts, changedAudiences, campaign?.audience, excludedAudiences]);

  const getImpressions = (): {
    banner_imp: number;
    video_imp: number;
    impressions: number;
  } => {
    const banner_imp = campaign?.banner_count ? bannerFreq * days * cartProspectCount : 0;
    const video_imp = campaign?.video_count ? videoFreq * days * cartProspectCount : 0;
    const impressions =
      banner_imp && video_imp ? banner_imp + video_imp : banner_imp ? banner_imp : video_imp;
    return { impressions, banner_imp, video_imp };
  };

  const getCardDetail = () => {
    getPaymentCards().then(res => {
      const data = res.data?.details;
      /* @ts-ignore */
      setCard(data);
      /* @ts-ignore */
      setSeletedCardId(res?.data?.details[0].id || '');
      setLoading(false);
    });
  };

  useEffect(() => {
    getCardDetail();
  }, []);

  function bannerCost() {
    if (campaign?.banner_count >= 1) {
      const data = (cartProspectCount * days * bannerFreq) / 1000;
      return data * bannerCPM;
    }
    return 0;
  }

  function videoCost() {
    if (campaign?.video_count >= 1) {
      const data = (cartProspectCount * days * videoFreq) / 1000;
      return data * videoCPM;
    }
    return 0;
  }

  function creditsPerMile(): any {
    return bannerCost() + videoCost();
  }

  function totalBudget() {
    if (campaign?.banner_count || campaign?.video_count) creditsPerMile();
    return creditsPerMile();
  }

  const diff = userData?.addons_credits - totalBudget();

  const History = useHistory();

  const postBudget = () => {
    isCheckoutLoaded(true);
    const { impressions, video_imp, banner_imp } = getImpressions() || {};

    const now = new Date();
    const start_Date = new Date(startDate).getTime();
    const ToDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    const start_date =
      start_Date === ToDay
        ? new Date().toJSON()
        : `${startDate.getFullYear()}-${
            startDate.getMonth() + 1 < 10
              ? `0${startDate.getMonth() + 1}`
              : startDate.getMonth() + 1
          }-${
            startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate()
          }T00:00:00.000Z` || campaign?.start_date;

    const data = {
      start_date,
      end_date:
        `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1
        }-${endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate()}T00:00:00.000Z` ||
        campaign?.end_date,
      days: days || campaign?.date,
      budget: parseFloat(totalBudget().toFixed(2)),
      video_imp: +video_imp.toFixed(2),
      banner_imp: +banner_imp.toFixed(2),
      impressions_perday: 3 || campaign?.impressions_perday,
      payment_source_id: seletedCardId,
    };

    if (budgetAdjustmentFlag && flagsReady) {
      data.video_imp_frequency = videoFreq;
      data.banner_imp_frequency = bannerFreq;
      data.changed_audiences = changedAudiences.map(
        (item: { id: number; prospects_counts: number }) => ({
          audience_id: item.id,
          prospects_count: item.prospects_counts,
        })
      );
      data.excluded_audiences = excludedAudiences;
    } else {
      data.impressions = impressions;
    }

    postCampaignBudget(campaign?.id, data)
      .then((res: any) => {
        if (res.detail) {
          toast.error(res.detail);
        }
        if (res?.data?.status === 'Creating') {
          setOpenSubmitModal(true);
        }
        isCheckoutLoaded(false);
      })
      .catch((err: any) => {
        toast.error(err.detail);
      });
  };

  const findCreatives = (creativeType: string) => {
    return campaign?.creatives?.filter(
      (creat: { content_type: string }) => creat.content_type === creativeType
    );
  };

  useEffect(() => {
    if (isCreditBuy) {
      toast.success('Credits has been added to your account');
      setIsCreditBuy(false);
    }
  }, [isCreditBuy]);

  const isActionDisabled =
    days < minFlightDuration || days < DEFAULT_MIN_FLIGHT_DATES || checkoutLoaded;

  const handleConfirmChanges = (updatedData: any) => {
    const {
      startDate,
      endDate,
      minDays: minFlightDuration,
      bannerFreq,
      videoFreq,
      excludedAudiences,
      changedAudiences,
    } = updatedData || {};

    setCampaignCostAccData((prev: any) => ({
      ...prev,
      startDate,
      endDate,
      minFlightDuration,
      bannerFreq,
      videoFreq,
      excludedAudiences,
      changedAudiences,
      isConfirmed: true,
    }));

    setEditModalOpen(false);
  };

  const cartAudienceCount = useMemo(() => {
    let count = campaign?.audience_count;

    if (excludedAudiences?.length) {
      count = count - excludedAudiences.length;
    }

    return count;
  }, [campaign?.audience_count, excludedAudiences]);

  return (
    <div className="SummaryCart">
      <FlexBox
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        padding="0 1.5rem 1rem"
        backgroundColor="#FFFFFF"
      >
        <Text size="xxl" on="white" weight="bold" UNSAFE_className="Summary_heading">
          Summary
        </Text>
        {isConfirmed && (
          <FlexBox
            justifyContent="space-between"
            alignItems="center"
            UNSAFE_style={{
              border: '1px solid #FA8C16',
              borderRadius: '6px',
              padding: '12px',
              marginTop: '1rem',
            }}
            gap="15px"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} color="#FA8C16" />
            <Text on="white" textAlign="left" UNSAFE_style={{ color: '#757575', fontSize: 14 }}>
              Changes you’ve made while editing final spend will not be saved unless you checkout
              and submit.
            </Text>
          </FlexBox>
        )}
      </FlexBox>
      {campaign?.audiences || campaign?.audience_count >= 1 || campaign?.audiences?.length >= 1 ? (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold" UNSAFE_className="SummaryCart__save_audience">
              Audience
            </Text>
          </div>
          <div className="SummaryCart__body-item">
            <Text UNSAFE_className="SummaryCart__body-item__overall_font" on="grey-050">
              <FontAwesomeIcon icon={faWalking} color="var(--color-secondary-300)" />
              <span className="SummaryCart__prospects_heading">{cartProspectCount} Prospects</span>
              <span className="SummaryCart__prospects_subheading">
                ({cartAudienceCount || campaign?.audiences}{' '}
                {`Audience${cartAudienceCount > 1 ? 's' : ''}`})
              </span>
            </Text>
          </div>
        </>
      ) : (
        ''
      )}

      {openSubmitModal ? (
        <Modal>
          <div
            className="OnboardingModal-welcome SummaryCart__submitModal"
            style={{ position: 'relative' }}
          >
            <img className="SummaryCart__lefticon" src={Lefticon} alt="lefticon" />
            <div className="OnboardingModal-welcomeHeader SummaryCart__submitModalContent">
              <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                <img src={succesTick} alt="succesTick" />
              </div>
              <div className="SummaryCart__title_container">
                <Text UNSAFE_className="SummaryCart__title" on="white" textAlign="center">
                  Your Campaign Is Submitted For Review
                </Text>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                UNSAFE_style={{ padding: '30px 30px' }}
                kind="primary"
                size="xl"
                width="50%"
                weight="bold"
                onClick={() => {
                  setOpenSubmitModal(false);
                  History.push('/campaigns');
                }}
                UNSAFE_className="SummaryCart__Back_to_campaigns"
              >
                Back to campaigns
              </Button>
            </div>
            <img className="SummaryCart__rigticon" src={Righticon} alt="righticon" />
          </div>
        </Modal>
      ) : (
        ''
      )}
      {/* Creatives Type */}

      {campaign?.creatives?.length > 0 ||
      campaign?.banner_count ||
      campaign?.video_count ||
      campaign?.direct_mail_count ? (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold" UNSAFE_className="SummaryCart__save_audience">
              Creative
            </Text>
          </div>
          <>
            {findCreatives('video')?.length !== 0 && (
              <div className="SummaryCart__body-item creative_tile">
                <div className="creative_item">
                  <Text on="grey-050" UNSAFE_className="SummaryCart__content_heading">
                    <img src={video} alt="" className="SummaryCart__itemImage" />
                    {findCreatives('video')?.length >= 1 &&
                      `${findCreatives('video')?.length}`}{' '}
                    Video{findCreatives('video')?.length > 1 ? 's' : ''}
                  </Text>
                  <Tooltip
                    placement="top"
                    trigger="hover"
                    showArrow
                    overlay={
                      <div
                        style={{
                          width: '255px',
                        }}
                      >
                        This video ad will be served to each prospect {videoFreq.toFixed(2)} times
                        per day.
                      </div>
                    }
                    getTooltipContainer={() => document.body}
                  >
                    <span>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}

            {findCreatives('banner')?.length !== 0 && (
              <div className="SummaryCart__body-item creative_tile">
                <div className="creative_item">
                  <Text UNSAFE_className="SummaryCart__content_heading" on="grey-050">
                    <img src={banner} alt="" className="SummaryCart__itemImage" />
                    {findCreatives('banner')?.length >= 1 &&
                      `${findCreatives('banner')?.length}`}{' '}
                    Digital Banner{findCreatives('banner')?.length > 1 ? 's' : ''}
                  </Text>
                  <Tooltip
                    placement="top"
                    trigger="hover"
                    showArrow
                    overlay={
                      <div
                        style={{
                          width: '255px',
                        }}
                      >
                        This banner ad will be served to each prospect {bannerFreq.toFixed(2)} times
                        per day.
                      </div>
                    }
                  >
                    <span>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        </>
      ) : (
        ''
      )}

      {isCheckout && (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold" UNSAFE_className="SummaryCart__save_audience">
              Flight Dates
            </Text>
          </div>
          <div className="SummaryCart__body-item">
            <Text UNSAFE_className="SummaryCart__content_heading" on="grey-050">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                color="#FFAB03"
                style={{ fontSize: '20px', marginRight: '10px' }}
              />
              {`${formattedDateRange} (${durationInDays} day${durationInDays > 1 ? 's' : ''})`}
            </Text>
          </div>
        </>
      )}

      {totalBudget() || campaign?.budget ? (
        <FlexBox flexDirection="column">
          <div className="SummaryCart__total_budget">
            <SummaryTotal
              isEditDisabled={isActionDisabled}
              total={totalBudget().toFixed(2) || campaign?.budget.toFixed(2)}
              handleOpen={() => setEditModalOpen(true)}
            />
          </div>
          <Spacer />
          <FlexBox paddingRight="1.5rem" paddingLeft="1.5rem">
            <Button
              size="xl"
              kind="primary"
              width="100%"
              weight="bold"
              UNSAFE_className="checkout_and_submit"
              disabled={isActionDisabled}
              loading={checkoutLoaded}
              onClick={() => setIsSelectCard(true)}
            >
              Checkout and Submit
            </Button>
          </FlexBox>
          {isSelectCard && (
            <Modal style={{ position: 'relative' }} offClick={() => setIsSelectCard(false)}>
              <div className="SummaryCart__AddCardModal">
                <img className="SummaryCart__leftcardicon" src={leftGroup} alt="leftcardicon" />
                <img className="SummaryCart__righcardicon" src={rightGroup} alt="righcardicon" />
                <img
                  className="BillingSection__cross"
                  onClick={() => setIsSelectCard(false)}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                {/* <AddCard /> */}
                <div className="SummaryCart__allCardlist">
                  <Text on="white" UNSAFE_className="SummaryCart__addCardHeading">
                    {/* @ts-ignore */}
                    {!card.length ? 'Add Card' : 'Select Card'}
                  </Text>
                  {!card.length ? (
                    <div className="BillingSection__main">
                      <img
                        className="BillingSection__billingIcon"
                        src={addcardbill}
                        alt="addcardbill"
                      />
                      <Button
                        type="button"
                        kind="primary"
                        size="m"
                        weight="bold"
                        UNSAFE_className="BillingSection__billingButton"
                        onClick={() => {
                          setIsAddCard(true);
                        }}
                      >
                        Add Card
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="SummaryCart__allCardlist_heading">
                        <div className="SummaryCart__heading_section">
                          <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                            Card Detail
                          </Text>
                        </div>

                        <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                          Expiry Date
                        </Text>
                      </div>
                      <div className="SummaryCart__card_list Modal__scrollable-content">
                        {card?.map((item: any) => (
                          <div
                            key={item.id}
                            className={
                              seletedCardId === item.id
                                ? 'SummaryCart__detailsCard_id'
                                : 'SummaryCart__detailsCard'
                            }
                          >
                            <div className="SummaryCart__billing_card">
                              <RadioButtonCustom
                                isCircular
                                checked={seletedCardId === item.id}
                                onChange={checked => setSeletedCardId(item.id)}
                              />
                              <img
                                className="SummaryCart__billing_card_brand-icon"
                                src={brand(item?.card.brand)}
                                alt={item?.card?.brand}
                              />
                              <div className="SummaryCart__billing_card_left">
                                <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                                  {item?.card?.masked_number}
                                </Text>
                                <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                                  {`${item?.card?.first_name} ${item?.card?.last_name}`}
                                </Text>
                              </div>
                            </div>
                            <div className="">
                              <Text on="white" UNSAFE_className="SummaryCart__heading_text_detail">
                                {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        className="SummaryCart__addCard"
                        onClick={() => {
                          setIsAddCard(true);
                          setIsSelectCard(false);
                        }}
                      >
                        <button className="SummaryCart__headingButton" type="button">
                          <img src={plusIcon} alt="uploadicon" style={{ marginRight: '10px' }} />{' '}
                          Add Card
                        </button>
                      </div>
                      <Button
                        kind="primary"
                        width="30%"
                        weight="bold"
                        UNSAFE_style={{
                          margin: '1.5rem auto 0 auto',
                        }}
                        UNSAFE_className="summary_card_done"
                        onClick={() => {
                          postBudget();
                          setIsSelectCard(false);
                        }}
                      >
                        Done
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Modal>
          )}
          {isAddCard && (
            <Modal style={{ position: 'relative' }}>
              <div className="BillingSection__AddCardModal">
                <img
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0px',
                    width: '29%',
                  }}
                  src={rightGroup}
                  alt="confirmleftcomb"
                />

                <img
                  className="BillingSection__cross"
                  onClick={() => {
                    setIsAddCard(false);
                    setIsSelectCard(true);
                  }}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                <div className="SummaryCart__campaginCard">
                  <p className="addCardHeading " style={{ marginBottom: '10px' }}>
                    Add Card
                  </p>

                  <AddCard
                    /* @ts-ignore */
                    getCardDetail={getCardDetail}
                    setIsAddCard={setIsAddCard}
                    setLoadings={setLoading}
                    setIsSelectCard={setIsSelectCard}
                  />
                </div>
                <img
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    left: '8px',
                    width: '28%',
                  }}
                  src={leftGroup}
                  alt="lconfirmleftcomb"
                />
              </div>
            </Modal>
          )}

          {checkoutLoaded && (
            <span
              style={{
                color: '#DC9300',
                marginTop: '8px',
                fontSize: '17px',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              The campaign is in progress to be created.
            </span>
          )}
          <Spacer />
        </FlexBox>
      ) : (
        ''
      )}

      {creditModalOpen && (
        <CreditModal
          offClick={() => setCreditModalOpen(false)}
          pointsNeeded={diff < 1 ? diff : 0}
          setIsCreditBuy={setIsCreditBuy}
        />
      )}
      {editModalOpen && (
        <SummaryEditModal
          onClose={() => setEditModalOpen(false)}
          bannerCost={bannerCost()}
          videoCost={videoCost()}
          days={days}
          startDate={startDate}
          endDate={endDate}
          onConfirm={handleConfirmChanges}
          minDays={minFlightDuration}
          prospectsCount={cartProspectCount}
          bannerFreq={bannerFreq}
          videoFreq={videoFreq}
          campaign={campaign}
          excludedAudiences={excludedAudiences}
          changedAudiences={changedAudiences}
        />
      )}
    </div>
  );
};
