import axios from 'axios';
import type { AxiosInstance, CreateAxiosDefaults } from 'axios';

import { BACKEND_HOST } from 'canva-integration/config';

import type { Method, Url, Data } from 'canva-integration/request-types';

const axiosConfig: CreateAxiosDefaults = {
  baseURL: BACKEND_HOST,
  withCredentials: true,
};

const instance: AxiosInstance = axios.create(axiosConfig);

instance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('beewo_token');
    if (token) config.headers.Authorization = `Bearer ${token}`;

    if (config.method === 'POST' || config.method === 'PUT') {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  error => Promise.reject(error)
);

export default async function request<T>(
  method: Method,
  url: Url,
  data: Data = null,
  headers?: { 'Content-Type': string }
): Promise<T> {
  try {
    const response = await instance<T>({ method, url, data, headers });
    return Promise.resolve<T>(response.data);
  } catch (error: unknown) {
    return Promise.reject(error);
  }
}
