import React, { useState } from 'react';
import moment from 'moment/moment';
import { PostMediaType, SocialMediaPostById } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import CustomIcons from 'assets/icons';
import { downloadPostCreative } from 'Utils/helpers';
import PostImagePreviewModal from './PostImagePreviewModal';

type PostContentProps = {
  post: SocialMediaPostById;
  getSocialMediaIcon: (socialType: number, pageImageUrl: string) => any;
  getSocialMediaChannel: (socialType: number) => any;
};
const PostContent = ({ post, getSocialMediaIcon, getSocialMediaChannel }: PostContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [previewMedia, setPreviewMedia] = useState<PostMediaType | null>(null);
  const onOpenPreviewModal = () => setPreviewModalOpen(true);
  const onClosePreviewModal = () => {
    setPreviewModalOpen(false);
    handlePreviewInfo(null);
  };
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const maxCharacters = 410;
  const text = post.description.startsWith('<br/>') ? post.description.slice(5) : post.description;
  const date = moment(post?.createdAt).format('MM/DD/YYYY');

  const displayedText = isExpanded
    ? text
    : text.slice(0, maxCharacters) + (text.length > maxCharacters ? '...' : '');

  const { medias } = post;
  const handlePreviewInfo = (media: PostMediaType | null) => {
    setPreviewMedia(media);
  };
  return (
    <>
      <div className="post-info">
        <div className="post-info-detail">
          Date: <span className="social-media-date">{date}</span>
        </div>
        <div className="post-info-detail">
          Channel:{' '}
          <span className="social-media-icon">{getSocialMediaIcon(post.socialType, '')}</span>
          <span className="social-media-date">{getSocialMediaChannel(post.socialType)}</span>
        </div>
        {post.pageName && (
          <div className="post-info-detail">
            Business Page:{' '}
            {post.pagePictureLink && (
              <span className="social-media-icon">
                {getSocialMediaIcon(post.socialType, post.pagePictureLink)}
              </span>
            )}
            <span className="social-media-date">{post.pageName}</span>
          </div>
        )}
      </div>
      <div className="post-info">
        <span className="description">Description</span>
        <div className="truncated-text-container">
          <div
            dangerouslySetInnerHTML={{ __html: displayedText }}
            className={`truncated-text ${isExpanded ? 'expanded' : ''}`}
          />
          {/* <p className={`truncated-text ${isExpanded ? 'expanded' : ''}`}>{displayedText}</p> */}
          {text.length > maxCharacters && (
            <button className="see-more-button" onClick={toggleExpand}>
              {isExpanded ? 'See Less' : 'See More'}
            </button>
          )}
        </div>
      </div>
      <div className="post-info">
        <span className="description">{medias.length} attachments</span>
        <div className="media-container">
          {medias.map((media, index) => (
            <div key={index} className="media-wrapper">
              <div className="media-img">
                <img src={media.url} alt={media.name} className="media" />
                <div className="media-actions">
                  <button
                    aria-label="view"
                    onClick={() => {
                      onOpenPreviewModal();
                      handlePreviewInfo(media);
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                  <button onClick={() => downloadPostCreative(media)} aria-label="download">
                    <CustomIcons name="download" color="##fff" fontSize={18} />
                  </button>
                </div>
              </div>
              <div className="media-name">
                <FontAwesomeIcon icon={faImage} />
                <span>{media.name}</span>
              </div>
            </div>
          ))}
          {previewModalOpen && previewMedia && (
            <PostImagePreviewModal
              media={previewMedia}
              offClick={onClosePreviewModal}
              key={previewMedia.id}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default PostContent;
