import React from 'react';
import './Impressionvalidator.scss';

type validatorType = {
  title?: string;
  icon?: JSX.Element;
  UNSAFE_CLASS_icon?: string | number | undefined;
  days?: number | undefined;
};

export const ImpressionValidator: React.FC<validatorType> = ({
  title,
  days,
  UNSAFE_CLASS_icon,
  icon,
}) => {
  return (
    <div style={{ marginTop: '8px' }} className="ImpressionValidator">
      <p className="DateRangeSelector__count_days error">Flight days cannot be less than {days}.</p>
    </div>
  );
};
