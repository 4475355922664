/* eslint-disable */
import React, { useState } from 'react';
import type { ChangeEvent } from 'react';

import classNames from 'classnames';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './LabelInputSelect.scss';

type TextInputType = {
  Options?: any;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  title?: string;
  requireTage?: any;
  type?: string;
  isError?: Boolean;
  icon?: IconProp | JSX.Element;
  placeHolder?: string | undefined;
  value?: any;
  // value?: string | number | readonly string[] | undefined
  wdt?: string | number | undefined;
  border?: string | number | undefined;
  fontSize?: string | number | undefined;
  color?: string | undefined;
  padding?: string | number | undefined;
  EyePassIcon?: JSX.Element;
  iconLeft?: any;
  iconRight?: any;
  style?: any;
  placeholder?: string;
  lableStyle?: any;
  imgIconStyle?: any;
  UNSAFE_ClassName?: string;
  defaultValue?: string;
  selectDisable?: boolean;
};

export const LabelInputSelect: React.FC<TextInputType> = ({
  Options,
  title,
  requireTage,
  onChange,
  placeholder,
  EyePassIcon,
  defaultValue,
  value,
  isError,
  iconLeft,
  iconRight,
  style,
  lableStyle,
  type = 'text',
  imgIconStyle,
  icon,
  wdt,
  border,
  fontSize,
  color,
  padding,
  UNSAFE_ClassName,
  selectDisable,
}) => {
  const [errir, seterrir] = useState('');
  return (
    <>
      <div className="label-input-select-container">
        <div
          style={style}
          className={classNames(
            isError ? 'did-floating-label-content did-error-input' : 'did-floating-label-content'
          )}
        >
          <select
            className="did-floating-selects"
            onChange={onChange}
            value={value}
            disabled={selectDisable}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {Options}
          </select>
          {!!value && (
            <label className="did-floating-labels">
              {title} <span style={{ color: '#BD0000', marginLeft: '-2%' }}>{requireTage}</span>
            </label>
          )}
        </div>
      </div>
    </>
  );
};
