import React, { useEffect, useState } from 'react';
import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom';
import { Button, Modal, Text } from '@eltoro-ui/components';
import { getPaymentCards, userDetail } from 'Requests';
import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import addcardbill from 'assets/Images/addcardbill.png';
import plusIcon from 'assets/Images/addcard.png';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'Redux/actions';
import { makePrimary, reactivateSubscription } from 'Requests/Request_Methods/chargebeeMethods';
import type { TCommonSubscription, TRootState } from 'types';
import AddCard from 'Pages/MyProfile/components/BillingSection/components/AddCard/AddCard';
import { useHistory } from 'react-router-dom';
import cross from 'assets/Images/cross.png';
import { UserStatus } from 'enums';
import { brand } from 'Utils/helpers';
import { Loading } from '../Loading';

const CardModal = ({
  isReactivationFlow,
  selectedSubscription,
  closeCardModal,
  setModalOpen,
}: {
  isReactivationFlow: boolean;
  selectedSubscription: TCommonSubscription | null;
  closeCardModal: () => void;
  setModalOpen: (value: boolean) => void;
}) => {
  const [card, setCard] = useState<any>([]);
  const [isSelectCard, setIsSelectCard] = useState(false);
  const [getCardDetailsLoading, setGetCardDetailsLoading] = useState(false);
  const [seletedCardId, setSeletedCardId] = useState('');
  const [isAddCard, setIsAddCard] = useState(false);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const closedSection = () => {
    if (
      (isReactivationFlow && userData.active_orderline_count) ||
      userData.status === UserStatus.PAYMENT_FAIL
    ) {
      closeCardModal();
      setModalOpen(false);
      history.push('/profile/billing');
    } else {
      window.localStorage.clear();
      history.go(0);
    }
  };

  const getCardDetail = () => {
    setGetCardDetailsLoading(true);
    getPaymentCards()
      .then(res => {
        const data = res.data?.details;
        /* @ts-ignore */
        setCard(data);
        /* @ts-ignore */
        setSeletedCardId(res?.data?.details[0].id || '');
        setGetCardDetailsLoading(false);
      })
      .finally(() => setGetCardDetailsLoading(false));
  };

  useEffect(() => {
    getCardDetail();
  }, []);

  const onReactivateAccount = async () => {
    try {
      if (userData.sub_name) {
        await reactivateSubscription({
          plan: selectedSubscription?.id,
        });
        const token = localStorage.getItem('beewo_token') || undefined;
        userDetail(token).then(res => {
          const userData = {
            ...res.data,
          };

          dispatch(setUser(userData));
          toast.success('Your account reactivation request was sent successfully.');
          history.push('/dashboard');
        });
      }
    } catch (e) {
      toast.error('Oops, your account reactivation request has failed.');
    }
  };
  return (
    <>
      <Modal style={{ position: 'relative' }} offClick={() => setIsSelectCard(false)}>
        <div className="SummaryCart__AddCardModal">
          <button type="button" className="ConfirmPayment__cross" onClick={closedSection}>
            <img src={cross} alt="Close" />
          </button>
          <img className="SummaryCart__leftcardicon" src={leftGroup} alt="leftcardicon" />
          <img className="SummaryCart__righcardicon" src={rightGroup} alt="righcardicon" />
          <div className="SummaryCart__allCardlist">
            <Text on="white" UNSAFE_className="SummaryCart__addCardHeading">
              {!card.length ? 'Add Card' : 'Select Card'}
            </Text>
            {getCardDetailsLoading ? (
              <div>
                <Loading />
              </div>
            ) : !card.length ? (
              <div className="BillingSection__main">
                <img className="BillingSection__billingIcon" src={addcardbill} alt="addcardbill" />
                <Button
                  type="button"
                  kind="primary"
                  size="m"
                  weight="bold"
                  UNSAFE_className="BillingSection__billingButton"
                  onClick={() => {
                    setIsAddCard(true);
                  }}
                >
                  Add Card
                </Button>
              </div>
            ) : (
              <>
                <div className="SummaryCart__allCardlist_heading">
                  <div className="SummaryCart__heading_section">
                    <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                      Card Detail
                    </Text>
                  </div>

                  <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                    Expiry Date
                  </Text>
                </div>
                <div className="SummaryCart__card_list Modal__scrollable-content">
                  {card?.map((item: any) => (
                    <div
                      key={item.id}
                      className={
                        seletedCardId === item.id
                          ? 'SummaryCart__detailsCard_id'
                          : 'SummaryCart__detailsCard'
                      }
                    >
                      <div className="SummaryCart__billing_card">
                        <RadioButtonCustom
                          isCircular
                          checked={seletedCardId === item.id}
                          onChange={checked => {
                            makePrimary(item.id);
                            setSeletedCardId(item.id);
                          }}
                        />
                        <img
                          className="SummaryCart__billing_card_brand-icon"
                          src={brand(item?.card.brand)}
                          alt={item?.card?.brand}
                        />
                        <div className="SummaryCart__billing_card_left">
                          <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                            {item?.card?.masked_number}
                          </Text>
                          <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                            {`${item?.card?.first_name} ${item?.card?.last_name}`}
                          </Text>
                        </div>
                      </div>
                      <div className="">
                        <Text on="white" UNSAFE_className="SummaryCart__heading_text_detail">
                          {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="SummaryCart__addCard">
                  <button
                    className="SummaryCart__headingButton"
                    type="button"
                    onClick={() => {
                      setIsAddCard(true);
                      setIsSelectCard(false);
                    }}
                  >
                    <img src={plusIcon} alt="uploadicon" style={{ marginRight: '10px' }} /> Add Card
                  </button>
                </div>
                <Button
                  kind="primary"
                  width="30%"
                  weight="bold"
                  UNSAFE_style={{
                    margin: '1.5rem auto 0 auto',
                  }}
                  UNSAFE_className="summary_card_done"
                  onClick={() => {
                    if (isReactivationFlow) {
                      onReactivateAccount();
                    }
                    setIsSelectCard(false);
                  }}
                >
                  {isReactivationFlow ? 'Confirm Payment' : 'Done'}
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>

      {isAddCard && (
        <Modal style={{ position: 'relative' }}>
          <div className="BillingSection__AddCardModal">
            <button
              type="button"
              className="ConfirmPayment__cross"
              onClick={() => setIsAddCard(false)}
            >
              <img src={cross} alt="Close" />
            </button>

            <img
              style={{
                position: 'absolute',
                bottom: '0',
                right: '0px',
                width: '29%',
              }}
              src={rightGroup}
              alt="confirmleftcomb"
            />

            <div className="SummaryCart__campaginCard">
              <p className="addCardHeading " style={{ marginBottom: '10px' }}>
                Add Card
              </p>

              <AddCard
                /* @ts-ignore */
                getCardDetail={getCardDetail}
                setIsAddCard={setIsAddCard}
                setIsSelectCard={setIsSelectCard}
              />
            </div>
            <img
              style={{
                position: 'absolute',
                top: '-20px',
                left: '8px',
                width: '28%',
              }}
              src={leftGroup}
              alt="lconfirmleftcomb"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default CardModal;
