import type { Dispatch, SetStateAction } from 'react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import ReactHtmlParser, { Transform } from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import moment from 'moment/moment';
import toast from 'react-hot-toast';

import { FlexBox } from '@eltoro-ui/components';
import { EmptyStatus, Loading } from 'Components';

import CustomIcons from 'assets/icons';

import folder from 'assets/Images/folder.png';

import { setUnReadNotification, setUnReadNotificationCount } from 'Redux/actions';
import {
  getUnReadNotificationsCounts,
  getUserNotifications,
  markAsReadAllNotifications,
  markAsReadSingleNotification,
} from 'Requests';

import { NotificationSettingType, NotificationStatusEnum } from 'enums';
import type { TNotifications, TUnReadTotalCounts } from 'types';
import { usePermissionContext } from 'contexts/PermissionContext';
import { isCountOver99 } from 'Utils/helpers';

import './NotificationsTooltip.scss';

type NotificationsTooltipProps = {
  SetIsNotificationToggleChange: Dispatch<SetStateAction<boolean>>;
};

type GroupedNotifications = {
  [key: string]: TNotifications[];
};

type NotificationStatusIcon = {
  [key in NotificationStatusEnum]?: ReactNode;
};

export function HtmlContent(htmlString: string) {
  const transform: Transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'a') {
      return (
        <Link to={node.attribs.href} key={index}>
          {node.children[0].data}
        </Link>
      );
    }

    return node.data;
  };

  return <div>{ReactHtmlParser(htmlString, { transform })}</div>;
}

const icons: NotificationStatusIcon = {
  [NotificationStatusEnum.INFORMATION]: <CustomIcons name="info" fontSize={18} />,
  [NotificationStatusEnum.SUCCESS]: <CustomIcons name="success" />,
  [NotificationStatusEnum.ERROR]: <CustomIcons name="error" />,
  [NotificationStatusEnum.WARNING]: <CustomIcons name="warning" fontSize={18} />,
};

export const NotificationsTooltip = ({
  SetIsNotificationToggleChange,
}: NotificationsTooltipProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<NotificationSettingType | ''>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<TNotifications[]>([]);
  const [counts, setCounts] = useState<TUnReadTotalCounts[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(Infinity);
  const [pageChange, setPageChange] = useState<number>(1);
  const { userIsFailed, userIsDeactivatedWithActiveOrderlines } = usePermissionContext();

  useEffect(() => {
    return () => {
      setIsLoading(true);
      setIsLoading1(false);
      setNotifications([]);
      setCounts([]);
      setNextPage(1);
      setLastPage(Infinity);
      setPageChange(1);
    };
  }, []);

  const _getUnReadNotificationsCounts = (isCancelled: boolean) => {
    getUnReadNotificationsCounts()
      .then(res => {
        if (!isCancelled) {
          const counts = res.data;
          if (counts) setCounts(counts);
        }
      })
      .catch(err => {
        if (!isCancelled) {
          toast.error(err.detail);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (counts.some(nots => !!nots.unreads)) {
      dispatch(setUnReadNotification(true));
      dispatch(setUnReadNotificationCount(counts.reduce((count, not) => count + not.unreads, 0)));
    } else {
      dispatch(setUnReadNotification(false));
      dispatch(setUnReadNotificationCount(0));
    }
  }, [counts]);

  useEffect(() => {
    let isCancelled = false;

    if (nextPage !== 1) setIsLoading1(true);
    else setIsLoading(true);
    if (!userIsFailed && !userIsDeactivatedWithActiveOrderlines) {
      _getUnReadNotificationsCounts(isCancelled);
      _getNotifications(isCancelled);
    }

    return () => {
      isCancelled = true;
    };
  }, [pageChange, activeTab]);

  const _getNotifications = (isCancelled: boolean, isToolTip = false) => {
    getUserNotifications(isToolTip ? 1 : nextPage, 20, activeTab).then(res => {
      if (!isCancelled) {
        if (res.data) {
          if (!isToolTip) {
            setNextPage(res.data.current_page + 1);
            setLastPage(res.data.total_pages);
          } else {
            setNextPage(2);
            setLastPage(res.data.total_pages);
          }
          if (notifications.length > 0 && res.data.current_page > 1 && !isToolTip) {
            setNotifications([...notifications, ...res.data.notifications]);
          } else {
            setNotifications(res.data.notifications);
          }
          if (isToolTip) setIsLoading(false);
          if (nextPage !== 1) setIsLoading1(false);
          else setIsLoading(false);
        } else if (nextPage !== 1) setIsLoading1(false);
        else if (isToolTip) setIsLoading(false);
        else setIsLoading(false);
      }
    });
  };

  const readSingleNotification = ({ id, route, read, source }: TNotifications) => {
    if (read && route) {
      history.push(route);
      return undefined;
    }

    if (!read)
      markAsReadSingleNotification([{ id, read: 1 }]).then(() => {
        if (route) {
          SetIsNotificationToggleChange(false);
          history.push(route);
        } else {
          setNotifications(notifications =>
            notifications.map(notification => {
              if (notification.id === id) return { ...notification, read: 1 };
              return notification;
            })
          );
          setCounts(counts =>
            counts.map(count => {
              if (count.source === source) return { ...count, unreads: count.unreads - 1 };
              return count;
            })
          );
        }
      });
    return undefined;
  };

  const markAsAllRead = () => {
    setIsLoading(true);
    markAsReadAllNotifications(1)
      .then(() => {
        _getNotifications(false, true);
        _getUnReadNotificationsCounts(false);
      })
      .catch(err => {
        toast.error(err.detail);
        setIsLoading(false);
      });
  };

  const onScroll = (e: any) => {
    if (
      Math.floor(e.target.scrollHeight - Math.abs(e.target.scrollTop)) === e.target.clientHeight
    ) {
      if (!isLoading1 && nextPage <= lastPage) {
        setPageChange(page => page + 1);
      }
    }
  };

  const getCount = (src: NotificationSettingType) => {
    const _count = counts.find(count => count.source === src && count.total)?.unreads;
    return _count || 0;
  };

  const grouped = useMemo<GroupedNotifications>(() => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    return notifications.reduce<GroupedNotifications>((group, notification) => {
      const createdAt = moment(`${notification.created_at}Z`);
      if (createdAt.isSame(today, 'd'))
        return { ...group, Today: [...('Today' in group ? [...group.Today] : []), notification] };

      if (createdAt.isSame(yesterday, 'd'))
        return {
          ...group,
          Yesterday: [...('Yesterday' in group ? [...group.Yesterday] : []), notification],
        };

      const createdAtFormat = createdAt.format('MMMM D, YYYY');
      return {
        ...group,
        [createdAtFormat]: [
          ...(createdAtFormat in group ? [...group[createdAtFormat]] : []),
          notification,
        ],
      };
    }, {});
  }, [notifications]);

  const getBody = () => {
    let component = <></>;
    if (isLoading) {
      component = <Loading />;
    } else if (notifications?.length === 0) {
      component = (
        <EmptyStatus
          icon={<img src={folder} alt="empty" />}
          heading="You do not have any notifications yet."
        />
      );
    } else {
      component = (
        <div
          className="body_container"
          onScroll={onScroll}
          style={{ overflowY: 'scroll', height: '400px' }}
        >
          {Object.entries(grouped).map(([title, notifications]) => {
            return (
              <FlexBox
                key={title}
                flexDirection="column"
                UNSAFE_className="notification-group-container"
              >
                <div className="notification-group-header">
                  <span className="notification-group-title">{title}</span>
                </div>
                <FlexBox flexDirection="column" UNSAFE_className="notification-group-content">
                  {notifications.map(notification => (
                    <ul
                      key={notification.id}
                      className={classNames('notification', {
                        'notification-unread': !notification.read,
                      })}
                      onClick={() => readSingleNotification(notification)}
                      role="presentation"
                    >
                      <li className="notification-status-dot">
                        <span>{icons[notification.severity_level]}</span>
                      </li>
                      <li className="notification_content">
                        <FlexBox justifyContent="space-between" alignItems="center" gap="20px">
                          <span className="notification_content-title">{notification.title}</span>
                          <span className="notification_created">
                            {title === 'Today'
                              ? moment(`${notification.created_at}Z`).subtract(1).fromNow()
                              : moment(`${notification.created_at}Z`).format('hh:mm A')}
                          </span>
                        </FlexBox>
                        <span className="notification_content-description">
                          {HtmlContent(notification.message)}
                        </span>
                      </li>
                    </ul>
                  ))}
                </FlexBox>
              </FlexBox>
            );
          })}
          {isLoading1 && nextPage <= lastPage && (
            <ul>
              <Loading style={{ minHeight: '0px' }} />
            </ul>
          )}
        </div>
      );
    }
    return component;
  };
  const allUnreads = isCountOver99(counts.reduce((count, not) => count + not.unreads, 0));

  return (
    <div
      className="HeaderDropdown__options"
      style={{ right: '1rem', zIndex: 9999, width: 546, position: 'fixed', top: 90 }}
    >
      <div className="notification_container-tooltip_wrapper">
        <div className="header">
          <span>Notifications</span>
          <span
            className={classNames('mark_all_as_read')}
            onClick={markAsAllRead}
            role="presentation"
          >
            Mark all as read
          </span>
        </div>
      </div>
      <div className="notification_container_tooltip">
        <div className="nested_container">
          <div className="header_container">
            <ul className="header_titles">
              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === '',
                })}
                role="presentation"
                onClick={() => {
                  setActiveTab('');
                  setNextPage(1);
                }}
              >
                <span className="notification-tab-source">All</span>
                {!!allUnreads && (
                  <span className={classNames('notification-tab-source-count')}>{allUnreads}</span>
                )}
              </li>

              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === NotificationSettingType.CAMPAIGN,
                })}
                role="presentation"
                onClick={() => {
                  setActiveTab(NotificationSettingType.CAMPAIGN);
                  setNextPage(1);
                }}
              >
                <span className="notification-tab-source">Campaign</span>
                {!!getCount(NotificationSettingType.CAMPAIGN) && (
                  <span className={classNames('notification-tab-source-count')}>
                    {isCountOver99(getCount(NotificationSettingType.CAMPAIGN))}
                  </span>
                )}
              </li>

              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === NotificationSettingType.CONTACTS,
                })}
                role="presentation"
                onClick={() => {
                  setActiveTab(NotificationSettingType.CONTACTS);
                  setNextPage(1);
                }}
              >
                <span className="notification-tab-source">Contacts</span>
                {!!getCount(NotificationSettingType.CONTACTS) && (
                  <span className={classNames('notification-tab-source-count')}>
                    {isCountOver99(getCount(NotificationSettingType.CONTACTS))}
                  </span>
                )}
              </li>
              <li
                className={classNames('notification-tab', {
                  'notification-tab-active': activeTab === NotificationSettingType.ACCOUNT,
                })}
                role="presentation"
                onClick={() => {
                  setActiveTab(NotificationSettingType.ACCOUNT);
                  setNextPage(1);
                }}
              >
                <span className="notification-tab-source">Account</span>
                {!!getCount(NotificationSettingType.ACCOUNT) && (
                  <span className={classNames('notification-tab-source-count')}>
                    {isCountOver99(getCount(NotificationSettingType.ACCOUNT))}
                  </span>
                )}
              </li>
            </ul>
          </div>
          {getBody()}
        </div>
      </div>
    </div>
  );
};
