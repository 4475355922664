import React, { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { FlexBox, Text, DateRangePicker } from '@eltoro-ui/components';
import moment from 'moment/moment';

interface Props {
  startDate: Date;
  endDate: Date;
  minDays: number;
  currentDays: number;
  days: number;
  onStartDateChange: (newDate: Date) => void;
  onEndDateChange: (newDate: Date) => void;
  setIsValid: Dispatch<
    SetStateAction<{
      cost: boolean;
      flightDates: boolean;
      audiences: boolean;
    }>
  >;
}

const FlightOptions = ({
  startDate,
  endDate,
  minDays,
  currentDays,
  days,
  onStartDateChange,
  onEndDateChange,
  setIsValid,
}: Props) => {
  const [dateInputValue, setDateInputValue] = useState('');
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    const formattedDateRange = `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
      endDate
    ).format('MM/DD/YYYY')}`;
    setDateInputValue(formattedDateRange);
  }, [startDate, endDate]);

  useEffect(() => {
    setCalendarOpen(false);
  }, [endDate]);

  const hasError = useMemo(() => currentDays < minDays, [currentDays, minDays]);

  useEffect(() => {
    setIsValid(prev => ({
      ...prev,
      flightDates: !hasError,
    }));
  }, [hasError]);

  return (
    <FlexBox
      UNSAFE_className="cost_item_wrapper"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <FlexBox
        UNSAFE_className="heading"
        justifyContent="space-between"
        alignItems="center"
        padding="0.75rem 1.5rem"
        UNSAFE_style={{ background: '#FEFEFE' }}
      >
        <FlexBox flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <Text
            on="white"
            weight="bold"
            textAlign="left"
            UNSAFE_style={{
              color: '#2B1F0A',
              fontSize: 16,
              textTransform: 'capitalize',
              marginBottom: '5px',
            }}
          >
            Flight Dates
          </Text>
          <Text on="white" textAlign="left" UNSAFE_style={{ color: '#757575', fontSize: 14 }}>
            Based on your chosen criteria flight days cannot be less than {minDays}.
          </Text>
        </FlexBox>
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}
        >
          {days} days
        </Text>
      </FlexBox>
      <FlexBox
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        UNSAFE_style={{
          background: '#FEFEFE',
          padding: '2rem',
          width: '100%',
          borderTop: '1px solid #E7E7E7',
          borderRadius: '0px 0px 12px 12px',
        }}
      >
        <FlexBox
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          UNSAFE_style={{ width: '100%', marginBottom: '1rem' }}
        >
          <input
            className={`custom_date_input ${hasError ? 'error' : ''}`}
            value={dateInputValue}
            readOnly
            onClick={() => setCalendarOpen(ps => !ps)}
          />
          {hasError && (
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{ color: '#FF2D38', fontSize: 14, marginTop: '5px' }}
            >
              Flight days cannot be less than {minDays}
            </Text>
          )}
        </FlexBox>
        {isCalendarOpen && (
          <FlexBox flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <div className="DateRangeSelector__date-selection">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                min={new Date()}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
              />
            </div>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default FlightOptions;
