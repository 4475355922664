import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { EmptyStatus, Section } from 'Components';

import { HtmlContent } from 'Pages/NotificationsTooltip';

import { getUserNotifications } from 'Requests';
import { usePermissionContext } from 'contexts/PermissionContext';
import type { TNotifications } from 'types';

import './NotificationContainer.scss';

export const NotificationContainer = () => {
  const [notifications, setNotifications] = useState<TNotifications[]>([]);
  const { userIsActive } = usePermissionContext();
  useEffect(() => {
    if (userIsActive) {
      getUserNotifications(1, 4).then(res => {
        if (res.data) setNotifications(res?.data?.notifications);
      });
    }
    return () => {
      setNotifications([]);
    };
  }, []);

  return (
    <Section
      title="Notifications"
      UNSAFE_className_text="campaignProgress__title"
      UNSAFE_className_container="notificationContainer__container"
      spacer={false}
      isCenter
      absoluteHeader
    >
      <div className="notificationContainer__listBox">
        {!notifications.length ? (
          <EmptyStatus heading="You do not have any notifications yet." />
        ) : (
          notifications.map(notification => {
            return (
              <div
                key={notification.id}
                className={classNames('notification', {
                  'notification-unread': !notification.read,
                })}
              >
                <div className="notification_content">
                  <span className="notification_content-title">{notification.title}</span>
                  <span className="notification_content-description">
                    {HtmlContent(notification.message)}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Section>
  );
};
