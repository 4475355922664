import React, { useEffect, useState } from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { useSelector } from 'react-redux';
import { TRootState } from 'types';
import SocialMediaPostList from './SocialMediaPostList';

const SocialMediaPosting = () => {
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const apiKey = userData.ms_api_key;
  const secretKey = userData.ms_secret_key;
  const clientSecret = userData.secret;
  const sdkSRC = process.env.REACT_APP_MESSAGESPRING_SDK as string;

  const [refreshPostTable, setRefreshPostTable] = useState(false);
  const refreshTable = () => {
    setRefreshPostTable(true);
  };
  useEffect(() => {
    if (apiKey && secretKey) {
      addJavascript(sdkSRC, '__messagespring_widget_script', () => {
        // @ts-ignore
        if (window.MS) {
          // @ts-ignore
          window.MS.init('#myContainer', {
            token: apiKey,
            secretKey,
            source: 1,
            clientSecret,
            refreshTable,
          });
        }
      });
    }
  }, []);

  const addJavascript = (src: string, id: string, callback: VoidFunction) => {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      if (callback) callback();
      return;
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    if (id) {
      scriptTag.id = id;
    }
    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null;
            callback();
          }
        };
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback();
        };
      }
    }

    // assign the src attribute
    scriptTag.src = src;
    // add the script to the page
    document.body.appendChild(scriptTag);
  };

  return (
    <FlexBox flexDirection="column">
      <div id="myContainer" />
      <SocialMediaPostList refreshPostTable={refreshPostTable} />
    </FlexBox>
  );
};

export default SocialMediaPosting;
