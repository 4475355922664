import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import type { TResPagination } from 'types';
import { getSocialMediaPosts } from 'Requests/Request_Methods/socialMediaMethods';
import type { Column } from 'react-table';

import { SocialMediaPost, TRootState } from 'types';
import { EmptyStatus, ReactTable } from 'Components';
import facebook from 'assets/Images/facebook.svg';
import instagram from 'assets/Images/instagram.svg';
import linkedIn from 'assets/Images/linkedin.svg';
import twitter from 'assets/Images/twitter.svg';
import emptyUser from 'assets/Images/empty-user.svg';
import { SocialMediaType } from 'Utils/constants';
import { FlexBox } from '@eltoro-ui/components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import type { MenuItem } from 'primereact/menuitem';
import type { SidebarProps } from 'primereact/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'rc-tooltip';
import SocialMediaActionAccessor from './SocialMediaActionAccessor';
import SocialMediaPostDetails from '../SocialMediaPostDetails';
import './SocialMediaPost.scss';

const SocialMediaPostList = ({ refreshPostTable }: { refreshPostTable: boolean }) => {
  const [fetchDataIsLoading, setFetchDataIsLoading] = useState(false);
  const [pagination, setPagination] = useState<TResPagination>({ total_count: 0, total_pages: 0 });
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [selectedPost, setSelectedPost] = useState<SocialMediaPost | null>(null);
  const [totalPosts, setTotalPosts] = useState(0);
  const [postDetailsView, setPostDetailsView] = useState<boolean>(false);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const getSocialMediaPage = (pagePictureLink: string) => {
    return {
      [SocialMediaType.SOCIAL_TYPE_FACEBOOK]: (
        <img
          src={pagePictureLink || facebook}
          alt="facebook"
          onError={e => (e.currentTarget.src = emptyUser)}
        />
      ),
      [SocialMediaType.SOCIAL_TYPE_INSTAGRAM]: (
        <img
          src={pagePictureLink || instagram}
          alt="instagram"
          onError={e => (e.currentTarget.src = emptyUser)}
        />
      ),
      [SocialMediaType.SOCIAL_TYPE_LINKEDIN]: (
        <img
          src={pagePictureLink || linkedIn}
          alt="linkedIn"
          onError={e => (e.currentTarget.src = emptyUser)}
        />
      ),
      [SocialMediaType.SOCIAL_TYPE_TWITTER]: (
        <img
          src={pagePictureLink || twitter}
          alt="twitter"
          onError={e => (e.currentTarget.src = emptyUser)}
        />
      ),
    };
  };
  const socialMediaChannelName = {
    [SocialMediaType.SOCIAL_TYPE_FACEBOOK]: 'Facebook',
    [SocialMediaType.SOCIAL_TYPE_INSTAGRAM]: 'Instagram',
    [SocialMediaType.SOCIAL_TYPE_LINKEDIN]: 'LinkedIn',
    [SocialMediaType.SOCIAL_TYPE_TWITTER]: 'Twitter',
  };
  const _fetchData = async () => {
    setFetchDataIsLoading(true);
    try {
      if (userData.ms_api_key && userData.ms_secret_key && userData.secret) {
        const res = await getSocialMediaPosts(
          page,
          10,
          userData.ms_api_key,
          userData.ms_secret_key,
          userData.secret,
          {}
        );

        if (res.data?.data?.length) {
          setPosts(res.data?.data);
          const { total, lastPage } = res.data.meta;
          setTotalPosts(total);
          setPagination({ total_count: total, total_pages: lastPage });
        }
        setFetchDataIsLoading(false);
      } else {
        setFetchDataIsLoading(false);
      }
    } catch (err: any) {
      if ('detail' in err && typeof err.detail === 'string') toast.error(err.detail);
      else toast.error('Something went wrong!');
      setFetchDataIsLoading(false);
    }
  };
  useEffect(() => {
    _fetchData();

    return () => {
      setPosts([]);
    };
  }, [page, refreshPostTable]);

  const getSocialMediaIcon = (socialType: number, pageImageUrl: string) => {
    return getSocialMediaPage(pageImageUrl)[socialType];
  };
  const getSocialMediaChannel = (socialType: number) => {
    return socialMediaChannelName[socialType];
  };

  const onViewDetailsAction =
    (post: SocialMediaPost): MenuItem['command'] =>
    () => {
      setSelectedPost(post);
      setPostDetailsView(true);
    };
  const onHidePostDetailsView: SidebarProps['onHide'] = () => {
    setSelectedPost(null);
    setPostDetailsView(false);
  };
  const columns: Column<SocialMediaPost>[] = [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Channel',
          accessor: originalRow => {
            return (
              <FlexBox alignItems="center" UNSAFE_className="channel" gap="4px">
                {getSocialMediaIcon(originalRow.socialType, originalRow.pagePictureLink)}{' '}
                <span className="truncate" title={originalRow.pageName ?? ''}>
                  {originalRow.pageName
                    ? originalRow.pageName
                    : getSocialMediaChannel(originalRow.socialType)}
                </span>
              </FlexBox>
            );
          },
        },
        {
          Header: 'Date',
          accessor: originalRow => moment(originalRow.createdAt).format('MM/DD/YYYY'),
        },
        {
          Header: 'Description',
          accessor: originalRow => {
            const descriptionMarkup = originalRow.description.startsWith('<br/>')
              ? originalRow.description.slice(5)
              : originalRow.description;
            return (
              <div
                dangerouslySetInnerHTML={{ __html: descriptionMarkup }}
                className="truncate"
                style={{
                  height: '28px',
                }}
              />
            );
          },
        },

        {
          Header: ' ',
          accessor: originalRow => {
            return (
              <div className="post-actions">
                <a
                  href={originalRow.permalink}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Open post link in a new tab"
                  className="go-to-post"
                >
                  <Tooltip
                    placement="top"
                    trigger="hover"
                    showArrow
                    overlay="Go to post"
                    getTooltipContainer={() => document.body}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} width={24} height={24} />
                  </Tooltip>
                </a>
                <SocialMediaActionAccessor
                  post={originalRow}
                  onViewDetailsAction={onViewDetailsAction}
                />
              </div>
            );
          },
        },
      ],
    },
  ];
  return (
    <div className="social-media-list-wrapper">
      <div className="social_media-post-count">
        You have <b>{totalPosts}</b> post{totalPosts > 1 ? 's' : ''}
      </div>
      <ReactTable<SocialMediaPost>
        title="social_media"
        className="social-media-posts"
        loading={fetchDataIsLoading}
        emptyText={
          <EmptyStatus
            heading="You do not have any social posts yet."
            subHeading="Connect social media accounts above to start posting."
          />
        }
        data={posts}
        columns={columns}
        currentPage={page}
        setPage={setPage}
        pageCount={pagination.total_pages}
        totalCount={pagination.total_count}
        selectFeature={false}
        isSortable={false}
        pageSize={10}
      />
      <SocialMediaPostDetails
        id={selectedPost?.id}
        visible={postDetailsView}
        onHide={onHidePostDetailsView}
        getSocialMediaIcon={getSocialMediaIcon}
        getSocialMediaChannel={getSocialMediaChannel}
      />
    </div>
  );
};

export default SocialMediaPostList;
