import { BACKEND_HOST } from 'canva-integration/config';
import request from 'canva-integration/request';

const endpoints = {
  AUTHORIZE: '/auth/authorize',
  REVOKE: '/auth/revoke',
  IS_AUTHORIZED: '/auth/is-authorized',
};

export const getCanvaAuthorization = async (email: string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      const url = new URL(endpoints.AUTHORIZE, BACKEND_HOST);
      url.searchParams.set('email', email);
      const windowFeatures = ['popup', 'height=800', 'width=800'];
      const authWindow = window.open(url.toString(), '', windowFeatures.join(','));

      window.addEventListener('message', event => {
        if (event.data === 'authorization_success') {
          resolve(true);
          authWindow?.close();
        } else if (event.data === 'authorization_error') {
          reject(new Error('Authorization failed'));
          authWindow?.close();
        }
      });
      const checkAuth = async () => {
        try {
          const { isAuthorized } = await checkAuthorizationStatus();
          resolve(isAuthorized);
        } catch (error) {
          reject(error);
        }
      };

      // Some errors from authorizing may not redirect to our servers,
      // in that case we need to check to see if the window has been manually closed by the user.
      const checkWindowClosed = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(checkWindowClosed);
          checkAuth();
        }
      }, 1000);
    } catch (error) {
      console.error('Authorization failed', error);
      reject(error);
    }
  });
};

export const revoke = async () => request('GET', endpoints.REVOKE);

export const checkAuthorizationStatus = async (): Promise<{
  isAuthorized: boolean;
}> => request('GET', endpoints.IS_AUTHORIZED);
