import React, { Component } from 'react';
import type { ReactNode } from 'react';

import SupportErrorPages from 'Pages/SupportErrorPages';

import './ErrorBoundary.scss';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // This can be used to log the error when we get logging capability
  public componentDidCatch(error: Error, errorInfo: any) {
    console.error('Through error:', error, errorInfo);
    // throw new Error(errorInfo)
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) return <SupportErrorPages type="server-error" />;
    return children;
  }
}

export default ErrorBoundary;
