import React from 'react';

import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import type { Design } from 'canva-integration/type';

import './CanvaDesignCard.scss';

interface CanvaDesignCardProps {
  design: Design;
  selected: boolean;
  onSelectDesign: () => void;
}

export default function CanvaDesignCard({
  design,
  selected,
  onSelectDesign,
}: CanvaDesignCardProps) {
  return (
    <button className={classNames('creative-card', { selected })} onClick={onSelectDesign}>
      {selected && (
        <div className="selected-icon">
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 25 }} />
        </div>
      )}
      <div className="creative-card-thumb-container">
        <img
          src={design.thumbnail?.url as string}
          alt={design.title ?? 'Untitled Design'}
          className="creative-thumb"
        />
      </div>
      <div className="creative-card-info">
        <span className="creative-card-info-name">{design.title ?? 'Untitled Design'}</span>
      </div>
    </button>
  );
}
