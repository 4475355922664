import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { Table, Text } from '@eltoro-ui/components';
import { getChargebeeTransactions } from 'Requests';
import { TableSelectionWrapper, Loading } from 'Components';
import './Transaction.scss';
import { TTransaction } from '../../../../types';

export const Transaction = () => {
  const [transactions, setTransactions] = useState<TTransaction[]>([]);
  const [loading, setLoading] = useState(true); // First page load
  const [isFetchingMore, setIsFetchingMore] = useState(false); // For subsequent page loads
  const [page, setPage] = useState(1); // Current page number
  const [hasMore, setHasMore] = useState(true); // If more data is available

  const wrapperRef = useRef<HTMLDivElement | null>(null); // Reference for scrolling container

  // Fetch transactions for a given page
  const fetchTransactions = useCallback(
    async (page: number) => {
      if (!hasMore) return;

      if (page === 1) setLoading(true);
      else setIsFetchingMore(true);

      try {
        const res = await getChargebeeTransactions(page, 5); // API call
        const newTransactions = res?.data?.transactions || [];

        setTransactions(prev => [...prev, ...newTransactions]); // Append new transactions
        setHasMore(newTransactions.length > 0); // If no data, stop further calls
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
        setIsFetchingMore(false);
      }
    },
    [hasMore]
  );

  // Fetch initial data and subsequent pages
  useEffect(() => {
    fetchTransactions(page);
  }, [fetchTransactions, page]);

  // Handle scroll events for infinite scrolling
  const handleScroll = useCallback(() => {
    if (!wrapperRef.current || isFetchingMore || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      setPage(prevPage => prevPage + 1); // Load next page
    }
  }, [isFetchingMore, hasMore]);

  // Attach scroll event listener
  useEffect(() => {
    const element = wrapperRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  // Loading states and table rendering
  const getLoading = () => {
    if (loading && page === 1) return <Loading />; // Loading for the first page

    if (!transactions.length && !loading)
      return (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h4>No Transactions</h4>
        </div>
      );

    return (
      <>
        <Table
          UNSAFE_className="transactions-table"
          noCheckbox
          striped
          rows={transactions}
          columns={[
            {
              path: '',
              width: '18%',
              alignment: 'left',
              RowCell: (transaction: TTransaction) => (
                <span>
                  <Text on="white" weight="bold" size="l" UNSAFE_className="transaction_name">
                    {transaction.name}
                  </Text>
                  <br />
                  <Text
                    on="white"
                    weight="light"
                    UNSAFE_className="transaction_date"
                    UNSAFE_style={{ fontSize: '16px', color: '#6D6D6D' }}
                  >
                    {moment(transaction.created_at).format('MM/DD/YYYY')} -{' '}
                    <span style={{ textTransform: 'capitalize' }}>{transaction.type}</span>
                  </Text>
                </span>
              ),
            },
            {
              path: '',
              alignment: 'right',
              RowCell: (transaction: TTransaction) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
                return (
                  <span>
                    <Text on="white" weight="bold" size="l" UNSAFE_className="transaction_cost">
                      {formatter.format(transaction.budget / 100)}
                    </Text>
                  </span>
                );
              },
            },
          ]}
        />
        {isFetchingMore && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Loading />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="Transactions__container">
      <TableSelectionWrapper
        selectionType="listing"
        selections={[]}
        actions={[]}
        UNSAFE_CLASSNAME_WRAPPER="Transactions__table-wrapper"
        ref={wrapperRef} // Attach ref for scrolling
      >
        <Text on="white" weight="normal" size="l" UNSAFE_className="heading">
          Transactions
        </Text>
        {getLoading()}
      </TableSelectionWrapper>
    </div>
  );
};
